import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  PRODUCT_TYPE_REQUEST,
  PRODUCT_TYPE_SUCCESS,
  PRODUCT_TYPE_FAILED,
  PRODUCT_TYPE_CREATE,
  PRODUCT_TYPE_CREATE_SUCCESS,
  PRODUCT_TYPE_CREATE_FAILED,
  PRODUCT_TYPE_DELETE,
  PRODUCT_TYPE_DELETE_SUCCESS,
  PRODUCT_TYPE_DELETE_FAILED,
  PRODUCT_TYPE_EDIT,
  PRODUCT_TYPE_EDIT_SUCCESS,
  PRODUCT_TYPE_EDIT_FAILED,
} from "../../../_utils/constants/ProductCatalog";


/*****
 * ProductType Actions
 */

function* fetchProductType({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/ProductStructure/GetProductStructure`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: PRODUCT_TYPE_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: PRODUCT_TYPE_FAILED, payload: error.response });
    }
  }
}

function* createProductType(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/ProductStructure/CreateProductStructure`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PRODUCT_TYPE_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_TYPE_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editProductType(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/ProductStructure/UpdateProductStructure`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PRODUCT_TYPE_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_TYPE_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteProductType(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/Product/api/ProductStructure/RemoveProductStructure?ProductStructureId=${encodeURIComponent(action.payload.ProductStructureId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: PRODUCT_TYPE_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_TYPE_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}



export default function* rootProductTypeList() {
  yield all([
    takeLatest(PRODUCT_TYPE_REQUEST, fetchProductType),
    takeLatest(PRODUCT_TYPE_CREATE, createProductType),
    takeLatest(PRODUCT_TYPE_DELETE, deleteProductType),
    takeLatest(PRODUCT_TYPE_EDIT, editProductType),
  ]);
}