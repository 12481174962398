import React, { Fragment } from "react";
import Model from "../../elements/CustomModel";
import ModelHeader from "../../elements/ModelHeader";
import ModelBody from "../../elements/ModelBody";
import ModelFooter from "../../elements/ModelFooter";
import Form from "../../elements/CustomForm";
import Select from "../../elements/CustomSelect";
import Input from "../../elements/CustomInput";
import Button from "../../elements/CustomButton";
import Checkbox from "../../elements/CustomCheckBox";
import { Row } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
// import TableScrollbar from "../../TableScrollbar";
const UpdateKits = ({
  isKitOpen,
  size,
  hideKitModel,
  otherProductStructure,
  laborCategories,
  mAddon,
  lAddon,
  kitLabor,
  kitMaterial,
  state,
  handleMaterialFilterChange,
  handleLaborFilterChange,
  handleLaborSelect,
  handleMaterialSelect,
  handleSubmit
}) => {

  return (
    <Fragment>
      <Model isOpen={isKitOpen} size={size}>
        <ModelHeader>Edit Kit</ModelHeader>
        <Form handleSubmit={handleSubmit}>
          <ModelBody>
            <Row className="justify-content-center">
              <h4>Material Add Ons</h4>
              <div className="gridtable">
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th>Check to Add</th>
                      <th>
                        <Select label={`Product Structure`} handleChange={handleMaterialFilterChange} result={otherProductStructure} ids="ProductTypeId" validate={false} />
                      </th>
                      <th>
                        <Input label={`Private Name`} ids="PrivateName" validate={false} handleChange={handleMaterialFilterChange} />
                      </th>
                      <th>
                        <Input label={`Manufacturer`} ids="Manufacturer" validate={false} handleChange={handleMaterialFilterChange} />
                      </th>
                      <th>
                        <Input label={`Style`} ids="Style" validate={false} handleChange={handleMaterialFilterChange} />
                      </th>
                      <th>
                        <Input label={`Color`} ids="Color" validate={false} handleChange={handleMaterialFilterChange} />
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                    {mAddon && state.StoreId ? mAddon.map((m, i) => (
                      <tr key={i}>
                        <td>
                          <Checkbox ids="Style" validate={false} value={kitMaterial.some(
                            d =>
                              d.ProductId ===
                              m.productId
                          )} handleChange={e =>
                            handleMaterialSelect(
                              e,
                              m.productId
                            )
                          } />
                        </td>
                        <td>{m.productStructure}</td>
                        <td>{m.privateName}</td>
                        <td>{m.manufacturer}</td>
                        <td>{m.style}</td>
                        <td>{m.color}</td>
                      </tr>
                    )) : (
                      <tr>
                        <td colSpan={`6`}>No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Row>

            <Row className="justify-content-center">
              <h4>Labor Add Ons</h4>
              <div className="gridtable">
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Check to Add</th>
                      <th>
                        <Select label={`Product Structure`} handleChange={handleLaborFilterChange} result={otherProductStructure} ids="ProductStructureIdForLabor" validate={false} />
                      </th>
                      <th>
                        <Select label={`Labor Category`} result={laborCategories} ids="LaborCategory" validate={false} handleChange={handleLaborFilterChange} />
                      </th>
                      <th>
                        <Input label={`Labor Name`} ids="LaborName" validate={false} handleChange={handleLaborFilterChange} />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {lAddon && state.StoreId ? lAddon.map((l, i) => (
                      <tr key={i}>
                        <td>
                          <Checkbox ids="Style" validate={false} value={kitLabor.some(
                            d =>
                              d.LaborItemId ===
                              l.laborItemId
                          )} handleChange={e =>
                            handleLaborSelect(
                              e,
                              l.laborItemId
                            )
                          } />
                        </td>
                        <td>{l.productStructure}</td>
                        <td>{l.laborCategory}</td>
                        <td>{l.laborName}</td>
                      </tr>
                    )) : (
                      <tr>
                        <td colSpan={`6`}>No Data Found</td>
                      </tr>
                    )}

                  </tbody>
                </Table>
              </div>
            </Row>

          </ModelBody>
          <ModelFooter>
            <Button variant="secondary" type="button" handleClick={hideKitModel}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </ModelFooter>
        </Form>
      </Model>
    </Fragment>
  )

}

export default UpdateKits;