import {
	CUSTOMER_LIST_REQUEST,
	CUSTOMER_LIST_SUCCESS,
	CUSTOMER_LIST_FAILED
} from "../../../_utils/constants/Customer";

const initialState = {
	customerList: [],
	totalCount: 0,
	isLoading: false,
	isSuccess: false,
	isCreated: false,
	isDeleted: false,
	isEdited: false,
	errors: {},
};

const CustomerInfoReducer = (state = initialState, action) => {
	switch (action.type) {

		case CUSTOMER_LIST_REQUEST:
			return {
				...state,
				isLoading: true,
				isSuccess: false,
				errors: {}
			}
		case CUSTOMER_LIST_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				customerList: decryptedData.result,
				totalCount: decryptedData.TotalCount,
				isLoading: false,
				isSuccess: true,
				errors: {}
			}
		case CUSTOMER_LIST_FAILED:
			return {
				...state,
				isLoading: false,
				isSuccess: false,
				errors: action.payload
			}

	};
	return state;
};


export default CustomerInfoReducer;