import React from "react";
import { Form } from 'react-bootstrap';

const CustomCheckBox = ({ label, handleChange, ids, value, validate, disabled }) => {
  return (
    <Form.Group controlId={ids}>
      {label ? (
        <Form.Label>{label}<span className="text-danger">{validate ? "*" : null}</span></Form.Label>
      ) : null}

      <Form.Check type="checkbox" name={ids} onChange={handleChange} checked={value} required={validate} disabled={disabled} />
      {validate ? (
        <Form.Check.Feedback type="invalid">
          {label ? label : ids} is required
        </Form.Check.Feedback>
      ) : null}

    </Form.Group>
  )
}

export default CustomCheckBox;