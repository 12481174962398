import React, { useState, memo } from "react";
import Sidebar from "./layouts/Sidebar";
import Header from "./layouts/Header";


const MainWrapper = memo(({ children }) => {
	const [isActive, setActive] = useState("false");
	const handleIsActive = (bool) => {
		setActive(bool);
	};
    return (
        <div className={`wrapper ${isActive ? "" : "open"}`}>
            <Sidebar />
            <div className="asideRight">
                <Header handleIsActive={handleIsActive} />
                {children}
            </div>
        </div>
    )
});

export default MainWrapper;