import {
  ASSIGNED_ACCESS_REQUEST,
  ASSIGNED_ACCESS_SUCCESS,
  ASSIGNED_ACCESS_FAILED,
  MENU_LIST_REQUEST,
  MENU_LIST_SUCCESS,
  MENU_LIST_FAILED
} from "../../_utils/constants/admin/Admin";
import _ from 'lodash';

const initialState = {
  assignedAccess: [],
  menuList: [],
  isLoading: false,
  errors: {},
  success: false,
};

const AdminReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ASSIGNED_ACCESS_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: {},
        success: false,
        assignedAccess: []
      }
    case ASSIGNED_ACCESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        assignedAccess: _.get(payload, "result", []),
        success: true,
        errors: {},
      }
    case ASSIGNED_ACCESS_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        errors: _.get(payload, "data", {}),
      }
    case MENU_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: {},
        success: false,
        assignedAccess: []
      }
    case MENU_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        menuList: _.get(payload, "result", []),
        success: true,
        errors: {},
      }
    case MENU_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        errors: _.get(payload, "data", {}),
      }
    default:
      return state;
  }
}

export default AdminReducer;