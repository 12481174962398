import React, { memo } from "react";
import { Form } from 'react-bootstrap';
import _ from "lodash";
const CustomSelect = ({ label, result, ids, value, handleChange, validate }) => {

  return (
    <Form.Group controlId={ids}>
      <Form.Label className="w-100">{label}<span className="text-danger">{validate ? "*" : null}</span></Form.Label>
      <Form.Select onChange={handleChange} value={value} defaultValue="" required={validate}>
        <option selected value="">Select</option>
        {!_.isEmpty(result) ? _.map(result, (res, i) => (
          <option value={res.value} key={i}>{res.label}</option>
        )) : null}
      </Form.Select>
      {validate ? (
        <Form.Control.Feedback type="invalid">
          {label ? label : ids} is required
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  )
}

export default memo(CustomSelect);