import React, { Fragment } from "react";
import { Table } from 'react-bootstrap';
import Icon from "react-icons-kit";
import { ic_mode_edit } from 'react-icons-kit/md';
import ConfirmDelete from "../../../elements/ConfirmDelete";
import Select from "../../../elements/CustomSelect";

const KitTable = ({
  productStructure,
  storeList,
  kitList,
  handleListChange,
  showConfirmDeleteModal,
  hideConfirmDeleteModal,
  handleConfirmDeleteSubmit,
  confirmDeleteModal,
  handleEdit
}) => {
  return (
    <Fragment>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>
              <Select label={`Store Name`} result={storeList} ids="StoreId" handleChange={handleListChange} validate={false} />
            </th>
            <th>
              <Select label={`Product Structure`} result={productStructure} handleChange={handleListChange} ids="ProductTypeId" validate={false} />
            </th>
            <th width="50" className="actions">
              <div className="d-flex">
                <span>Edit</span>
                <span>Delete</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {kitList ? kitList.map((k, i) => (
            <tr key={i}>
              <td>{k.storeName} </td>
              <td>{k.productStructure}</td>
              <td>
                <div className="btn-group">
                  <Icon
                    role="button"
                    className="w-54 d-flex justify-content-center"
                    size={18}
                    icon={ic_mode_edit}
                    onClick={(e) => handleEdit(e, k)}
                  />
                  <ConfirmDelete
                    variant="primary"
                    title={`Kit`}
                    showConfirmDeleteModal={() => showConfirmDeleteModal(k.kitId)}
                    hideConfirmDeleteModal={hideConfirmDeleteModal}
                    handleConfirmDeleteSubmit={handleConfirmDeleteSubmit}
                    confirmDeleteModal={confirmDeleteModal}
                  />
                </div>
              </td>
            </tr>
          )) : null}

        </tbody>
      </Table>

    </Fragment>
  )
}

export default KitTable