import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit } from 'react-icons-kit/md';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import Create from "./models/CreateProductType";
import ConfirmDelete from "./models/ConfirmDelete";
import Edit from "./models/EditProductType";
import {
  PRODUCT_TYPE_REQUEST,
  PRODUCT_TYPE_CREATE,
  PRODUCT_TYPE_DELETE,
  PRODUCT_TYPE_EDIT,
  PRODUCT_TYPE_STATE_CHANGE,
  UNIT_REQUEST,
  SOLD_BY_REQUEST,
} from "../../../../_utils/constants/ProductCatalog";
import Loader from "../../../layouts/Loader";
import { ToastContainer, toast } from 'react-toastify';

const ProductType = ({
  auth: { auth, isAuthenticated },
  ProductType: { valueList, isCreated, isDeleted, isEdited, isLoading },
  unit,
  SoldBy,
  fetchProductType,
  submitProductTypeCreate,
  submitProductTypeDelete,
  submitProductTypeEdit,
  changeReducerState,
  fetchUnit,
  fetchSoldBy
}) => {

  // states declarations
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [salesProductTypeName, setProductTypeName] = useState('');
  const [sortOrder, setSortOrder] = useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [deleteProductTypeId, setDeleteProductTypeId] = useState('');
  const [show, setShow] = useState(false);
  const [ids, setIds] = useState("");
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState({
    "productType": "",
    "soldById": "",
    "unitId": 10,
    "procurement": "",
    "orderBy": "",
    "invPrefix": "",
    "defaultGpm": "",
    "defaultFreightCharges": "",
    "requirePoitemInfo": true,
    "allowManualItemEntry": true,
    "allowManualPicking": true,
    "seamed": "",
  })
  const reset = () => {
    setState({
      "productType": "",
      "soldById": "",
      "unitId": "",
      "procurement": "",
      "orderBy": "",
      "invPrefix": "",
      "defaultGpm": "",
      "defaultFreightCharges": "",
      "requirePoitemInfo": true,
      "allowManualItemEntry": true,
      "allowManualPicking": true,
      "seamed": ""
    })
  }
  // use effect function called for authentication
  useEffect(() => {
    if (isAuthenticated) {
      fetchProductType(auth[0].token);
    }
  }, [auth, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUnit(auth[0].token);
    }
  }, [auth, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchSoldBy(auth[0].token);
    }
  }, [auth, isAuthenticated]);
  // use effect function called after api success
  useEffect(() => {
    if (isCreated === true) {
      toast.success("Product structure created successfully.");
      fetchProductType(auth[0].token);
      changeReducerState();
      setValidated(false);
      reset()
    }
    if (isDeleted === true) {
      toast.success("Product structure deleted successfully.");
      fetchProductType(auth[0].token);
      changeReducerState();
    }
    if (isEdited === true) {
      toast.success("Product structure updated successfully.");
      fetchProductType(auth[0].token);
      changeReducerState();
      //setValidated(false);
      //reset()
    }



  }, [isCreated, isDeleted, isEdited]);


  const handleChange = (e) => {
    const { id, value } = e.target;
    setState({ ...state, [id]: value })

  }

  // function to open create modal
  const showCreateModal = () => {
    setCreateModalOpen(true);
    setValidated(false);
  };

  // function to close create modal
  const hideCreateModal = (e) => {

    reset()
    setCreateModalOpen(false);
    setShow(false)
    setIds("");
    setValidated(false);
  };

  // function called for input value change of create modal
  const handleSalesStageNameChange = (e) => {
    setProductTypeName(e.target.value);
  }
  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  }

  // function called for create sales stage create api
  const handleCreateModalSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      submitProductTypeCreate({
        "data": {
          "ProductStructure": state.productStructure,
          "SoldById": parseInt(state.soldById),
          "UnitId": state.unitId ? parseInt(state.unitId) : 10,
          "Procurement": state.procurement,
          "OrderBy": state.orderBy,
          "InvPrefix": state.invPrefix ? state.invPrefix : "",
          "DefaultGpm": state.defaultGpm ? parseFloat(state.defaultGpm) : 0,
          "DefaultFreightCharges": state.defaultFreightCharges ? parseFloat(state.defaultFreightCharges) : 0,
          "RequirePoitemInfo": state.requirePoitemInfo === 'true' ? true : false,
          "AllowManualItemEntry": state.allowManualItemEntry === 'true' ? true : false,
          "AllowManualPicking": state.allowManualPicking === 'true' ? true : false,
          "Seamed": state.seamed,
          "CreatedBy": auth[0].userId
        },
        "token": auth[0].token
      });
      hideCreateModal();

    }
    setValidated(true);
  }

  // function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteProductTypeId(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteProductTypeId('');
    setConfirmDeleteModal(false);
  };

  // function called for delete sales stage delete api
  const handleConfirmDeleteSubmit = () => {
    submitProductTypeDelete({
      "ProductStructureId": deleteProductTypeId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }

  // function called on edit icon click
  const handleEditClick = (data) => {
    setState(data);
    setIds(data.productStructureId);
    setShow(true)
    /// setValidated(false);
  }

  // function called to save edited data
  const handleEditSaveClick = (e, data) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      submitProductTypeEdit({
        "data": {
          "ProductStructureId": ids,
          "ProductStructure": state.productStructure,
          "SoldById": parseInt(state.soldById),
          "UnitId": state.unitId ? parseInt(state.unitId) : 1,
          "Procurement": state.procurement,
          "OrderBy": state.orderBy,
          "InvPrefix": state.invPrefix ? state.invPrefix : "",
          "DefaultGpm": state.defaultGpm ? parseFloat(state.defaultGpm) : 0,
          "DefaultFreightCharges": state.defaultFreightCharges ? parseFloat(state.defaultFreightCharges) : 0,
          "RequirePoitemInfo": state.requirePoitemInfo === 'true' ? true : false,
          "AllowManualItemEntry": state.allowManualItemEntry === 'true' ? true : false,
          "AllowManualPicking": state.allowManualPicking === 'true' ? true : false,
          "Seamed": state.seamed,
        },
        "token": auth[0].token
      });

      setValidated(true);
      hideCreateModal();
    }

  }

  if (!isAuthenticated) return <Navigate to="/login" />;

  return (
    <MainWrapper>
      <Loader loading={isLoading}>
        <div className="container">
          <ToastContainer />
          <div className="sub-header mt-5 mb-3">
            <h2>Category</h2>
            <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back</Link>
            <Create
              variant="primary"
              title={`ProductType`}
              createModalOpen={createModalOpen}
              showCreateModal={showCreateModal}
              hideCreateModal={hideCreateModal}
              salesStageName={salesProductTypeName}
              handleSalesStageNameChange={handleSalesStageNameChange}
              sortOrder={sortOrder}
              handleSortOrderChange={handleSortOrderChange}
              handleCreateModalSubmit={handleCreateModalSubmit}
              handleChange={handleChange}
              state={state}
              units={unit.valueList}
              SoldBy={SoldBy.valueList}
              validated={validated}
            />
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>Category</th>
                <th>Seamed</th>
                <th>Ordered By</th>
                <th>Sold By</th>
                {/*<th>ProductTypeName</th>
                <th className="text-center">Procurement</th>*/}
                <th width="50" className="actions">
                  <div className="d-flex">
                    <span>Edit</span>
                    <span>Delete</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {valueList && valueList.length > 0 ? (
                valueList.map((item, index) => (

                  <tr key={index}>
                    <td> {item.productStructure}</td>
                    <td>
                      {item.seamed ? "Yes" : "No"}
                    </td>
                    <td> {item.orderBy}</td>
                    <td> {item.soldByName}</td>
                    <td className="actions">
                      <div className="btn-group">
                        <Icon role="button" className="w-54 d-flex justify-content-center" size={18} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
                        <ConfirmDelete variant="primary" title={`Product Structure`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.productStructureId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    No Data Found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Loader>
      <Edit
        state={state}
        handleChange={handleChange}
        handleEditSaveClick={handleEditSaveClick}
        show={show}
        hideCreateModal={hideCreateModal}
        units={unit.valueList}
        SoldBy={SoldBy.valueList}
        title={`ProductType`}
        validated={validated}
      />
    </MainWrapper>
  )
}

const mapStateToProps = ({ auth, ProductType, unit, SoldBy }) => {
  return {
    auth,
    ProductType,
    unit,
    SoldBy
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchProductType: (token) => dispatch({ type: PRODUCT_TYPE_REQUEST, token }),
    submitProductTypeCreate: (data) => dispatch({ type: PRODUCT_TYPE_CREATE, payload: data }),
    submitProductTypeDelete: (data) => dispatch({ type: PRODUCT_TYPE_DELETE, payload: data }),
    submitProductTypeEdit: (data) => dispatch({ type: PRODUCT_TYPE_EDIT, payload: data }),
    changeReducerState: () => dispatch({ type: PRODUCT_TYPE_STATE_CHANGE }),
    fetchUnit: (token) => dispatch({ type: UNIT_REQUEST, token }),
    fetchSoldBy: (token) => dispatch({ type: SOLD_BY_REQUEST, token }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductType);