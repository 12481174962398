import React, { Fragment, useState, useMemo, useEffect } from "react";
import MainWrapper from "../../../MainWrapper";
import Loader from "../.././../layouts/Loader";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back } from 'react-icons-kit/md';
import CustomButton from "../../../elements/CustomButton";
import Table from "./KitTable";
import Model from "./AddEdit";
import {
  PRODUCT_TYPE_REQUEST,
} from "../../../../_utils/constants/ProductCatalog";
import {
  STORE_INFO_REQUEST,
} from "../../../../_utils/constants/admin/StoreInfo";
import {
  MATERIAL_ADDONS_REQUEST,
  LABOR_ADDONS_REQUEST,
  KIT_CREATE,
  KIT_REQUEST,
  KIT_EDIT_REQUEST,
  KIT_DELETE,
  KIT_UPDATE_REQUEST,
  KIT_CREATED_REQUEST
} from "../../../../_utils/constants/Kits";
import {
  LABOR_CATEGORY_REQUEST
} from "../../../../_utils/constants/Labor";
import { connect } from "react-redux";
import { toast } from 'react-toastify';

const Kits = ({
  auth: { auth, isAuthenticated },
  ProductType,
  StoreInfo: { storeInfo },
  kits: {
    materialAddons,
    laborAddons,
    isCreated,
    kits,
    kit,
    isEditedList,
    isUpdated,
    isDeleted,
    createdMessage,
    isLoading
  },
  fetchProductType,
  fetchStoreInfo,
  fetchMaterialAddons,
  fetchLaborAddons,
  fetchLaborCategory,
  LaborCategory: { laborCategory },
  createKit,
  fetchKit,
  deleteKit,
  fetchEditKit,
  updateKit,
  fetchEditCreated
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteKitId, setDeleteKitId] = useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [validated, setValidated] = useState(false);
  // const [isUnique, setIsUnique] = useState(false);
  const [prevData, setPrevData] = useState({
    "StoreId": "",
    "ProductTypeId": "",
  })
  const [state, setState] = useState({
    "StoreId": "",
    "ProductTypeId": "",
  })
  const [materialFilter, setMaterialFilter] = useState({
    "ProductTypeId": "",
    "StoreId": "",
    "PrivateName": "",
    "Manufacturer": "",
    "Style": "",
    "Color": ""
  })
  const [laborFilter, setLaborFilter] = useState({
    "ProductTypeId": "",
    "ProductStructureIdForLabor": "",
    "StoreId": "",
    "LaborCategory": "",
    "LaborName": ""
  })
  const [kitMaterial, setKitMaterial] = useState([]);
  const [kitLabor, setKitLabor] = useState([]);
  const [editId, setEditId] = useState("");
  const [listFilter, setListFilter] = useState({
    "StoreId": 0,
    "ProductTypeId": 0,
  })


  const handleMaterialSelect = (e, id) => {
    if (!e.target.checked) {
      setKitMaterial(kitMaterial => kitMaterial.filter(d => d.ProductId !== id));
    } else {
      setKitMaterial(kitMaterial => [...kitMaterial, { ProductId: id }]);
    }
  };



  const handleLaborSelect = (e, id) => {
    if (!e.target.checked) {
      setKitLabor(kitLabor => kitLabor.filter(d => d.LaborItemId !== id));
    } else {
      setKitLabor(kitLabor => [...kitLabor, { LaborItemId: id }]);
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {

      if (!kitMaterial.length > 0 && !kitLabor.length > 0) {
        toast.error("At least one item is required.")
      } else {

        if (editId) {
          var requestData =
          {
            "data": {
              "KitId": editId,
              "StoreId": parseInt(state.StoreId),
              "ProductStructureId": parseInt(state.ProductTypeId),
              "KitMaterial": kitMaterial,
              "KitLabor": [...new Map(kitLabor.map(item => [JSON.stringify(item), item])).values()],
              "CreatedBy": auth[0].userId
            },
            "token": auth[0].token
          }

          updateKit(requestData)
        } else {
          var requestData =
          {
            "data": {
              "StoreId": parseInt(state.StoreId),
              "ProductStructureId": parseInt(state.ProductTypeId),
              "KitMaterial": kitMaterial,
              "KitLabor": [...new Map(kitLabor.map(item => [JSON.stringify(item), item])).values()],
              "CreatedBy": auth[0].userId
            },
            "token": auth[0].token
          }
          createKit(requestData);
        }
      }
    }
    setValidated(true);

  }


  const reset = () => {
    setState({
      "StoreId": 0,
      "ProductTypeId": 0,
    })
    setPrevData({
      "StoreId": 0,
      "ProductTypeId": 0,
    })
    setKitMaterial([])
    setKitLabor([])
    setMaterialFilter({
      "ProductTypeId": "",
      "StoreId": "",
      "PrivateName": "",
      "Manufacturer": "",
      "Style": "",
      "Color": ""
    })
    setLaborFilter({
      "ProductTypeId": "",
      "ProductStructureIdForLabor": "",
      "StoreId": "",
      "LaborCategory": "",
      "LaborName": ""
    })
    setListFilter({
      "StoreId": 0,
      "ProductTypeId": 0,
    })
    setEditId("");
    setValidated(false);
  }


  const handleModal = (e) => {
    e.preventDefault();
    reset();
    setIsOpen(!isOpen)
  }

  const hideModel = () => {
    setIsOpen(false)
    reset();

  }

  const handleListChange = (e) => {
    const { id, value } = e.target;
    setListFilter({
      ...listFilter,
      [id]: value ? value : 0
    })
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState({
      ...state,
      [id]: value
    })

    setMaterialFilter({
      ...materialFilter,
      [id]: value
    })
    setLaborFilter({
      ...laborFilter,
      [id]: value
    })
    setKitMaterial([]);
    setKitLabor([]);


  }
  // use effect function called after api success
  useMemo(() => {
    if (isCreated === true) {
      toast.success("Kit created successfully.");
      hideModel();
    }
    if (isDeleted === true) {
      toast.success("Kit deleted successfully.");
      //changeReducerState();
    }

    if (isUpdated === true) {
      toast.success("Kit updated successfully.")
      // changeReducerState();
      hideModel();
    }
    fetchKit(auth[0].token, listFilter)
  }, [isCreated, isDeleted, isUpdated]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchProductType(auth[0].token);
    }
  }, [auth, isAuthenticated]);
  useEffect(() => {
    if (isAuthenticated) {
      fetchStoreInfo(auth[0].token);
    }
  }, [auth, isAuthenticated]);
  useEffect(() => {
    if (isAuthenticated) {
      fetchLaborCategory(auth[0].token)
    }

  }, [isAuthenticated, auth])
  useEffect(() => {
    if (isAuthenticated && !editId) {
      fetchKit(auth[0].token, listFilter)
    }
  }, [isAuthenticated, auth, listFilter])
  useEffect(() => {
    if (isAuthenticated && materialFilter.StoreId && materialFilter.ProductTypeId) {
      fetchMaterialAddons(auth[0].token, materialFilter);
    }
  }, [auth, isAuthenticated, materialFilter, isEditedList]);
  useEffect(() => {
    if (isAuthenticated && laborFilter.StoreId && laborFilter.ProductTypeId) {
      fetchLaborAddons(auth[0].token, laborFilter);
    }
  }, [auth, isAuthenticated, laborFilter, isEditedList]);
  const checkAlert = useMemo(() => {
    if ((parseInt(prevData.StoreId) === parseInt(state.StoreId))
      && (parseInt(prevData.ProductTypeId) === parseInt(state.ProductTypeId))) {
      return true;
    } else {
      return false;
    }
  }, [state, prevData])

    // use effect function called after api success
    useMemo(() => {
      if (createdMessage === 'This Kit is already created , please use the edit option to modify.' && !checkAlert) {
        toast.error("This Kit is already created , please use the edit option to modify.")
      }
    }, [createdMessage, checkAlert]);
  useMemo(() => {

    if ((isAuthenticated && state.StoreId !== 0 && !checkAlert)) {

      fetchEditCreated(auth[0].token, state, isEditedList)
    }

  }, [auth, isAuthenticated, state,checkAlert])
  // function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteKitId(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteKitId('');
    setConfirmDeleteModal(false);
  };
  // function called for delete age demographic delete api
  const handleConfirmDeleteSubmit = () => {
    deleteKit({
      "KitId": deleteKitId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }
  const handleFilter = (e) => {
    const { id, value } = e.target;
    setMaterialFilter({
      ...materialFilter,
      [id]: value
    })
    setLaborFilter({
      ...laborFilter,
      [id]: value
    })
  }
  const handleMaterialFilterChange = (e) => {
    const { id, value } = e.target;
    setMaterialFilter({
      ...materialFilter,
      [id]: value
    })
    if (id === "ProductTypeId" && !value && state.ProductTypeId) {
      setMaterialFilter({
        ...materialFilter,
        [id]: state.ProductTypeId
      })
    }
  }
  const handleLaborFilterChange = (e) => {
    const { id, value } = e.target;
    setLaborFilter({
      ...laborFilter,
      [id]: value
    })
    if (id === "ProductTypeId" && !value && state.ProductTypeId) {
      setLaborFilter({
        ...laborFilter,
        [id]: state.ProductTypeId
      })
    }
  }
  const productStructure = useMemo(() => {
    if (ProductType) {
      var defaultV = ProductType.valueList.filter((p) => p.isDefault === true);
      return defaultV.map(v => ({ label: v.productStructure, value: v.productStructureId }))
    }


  }, [ProductType])

  const otherProductStructure = useMemo(() => {
    if (ProductType) {
      var other = ProductType.valueList.filter((p) => p.isDefault !== true);
      return other.map(v => ({ label: v.productStructure, value: v.productStructureId }))
    }


  }, [ProductType])

  const storeList = useMemo(() => {
    if (storeInfo)
      return storeInfo.map(v => ({ label: v.storeName, value: v.storeId }))
  }, [storeInfo])
  const laborCategories = useMemo(() => {
    if (laborCategory)
      return laborCategory.map(v => ({ label: v.laborCategory, value: v.laborCategoryId }))
  }, [laborCategory])
  const mAddon = useMemo(() => materialAddons, [materialAddons])

  const lAddon = useMemo(() => laborAddons, [laborAddons])

  useMemo(() => {
    laborAddons && laborAddons.map((labor) => {
      if (labor.isSelected) {
        setKitLabor(kitLabor => [...kitLabor, { LaborItemId: labor.laborItemId }]);
      }
    })
  }, [laborAddons])

  const kitList = useMemo(() => kits, [kits])
  const handleEdit = (e, data) => {
    e.preventDefault();
    let filter = {
      "StoreId": data.storeId,
      "ProductTypeId": data.productStructureId,
    }
    setState({
      "StoreId": data.storeId,
      "ProductTypeId": data.productStructureId,
    })
    setPrevData({
      "StoreId": data.storeId,
      "ProductTypeId": data.productStructureId,
    })
    setMaterialFilter({
      ...materialFilter,
      "StoreId": data.storeId,
      "ProductTypeId": data.productStructureId
    })
    setLaborFilter({
      ...laborFilter,
      "StoreId": data.storeId,
      "ProductTypeId": data.productStructureId,
      "ProductStructureIdForLabor": '0'
    })
    setEditId(data.kitId);
    if (isAuthenticated && filter.StoreId !== '') {
      fetchEditKit(auth[0].token, filter)
    }

  }
  useEffect(() => {
    if (isEditedList) {

      let data = kit ? kit[0] : [];

      if (data) {
        if (data.kitMaterial) {

          setKitMaterial(data.kitMaterial.filter(labor => {
            if (labor.isSelected === true) {
              return {
                ProductId: labor.productId
              }
            }

          }).map(m => { return { "ProductId": m.productId } }))
        }
        if (data.kitLabor) {

          setKitLabor(data.kitLabor.filter(d => {
            if (d.isSelected === true) {
              return d
            }
          }).map(m => { return { "LaborItemId": m.laborItemId } }))
        }
      }

      setIsOpen(!isOpen)
    }
  }, [isEditedList])
  useMemo(() => {
    if (!isAuthenticated) return <Navigate to="/login" />;
  }, [auth, isAuthenticated]);

  return (
    <Fragment>
      <MainWrapper>
        <Loader loading={false}>
          <div className="container">
            <div className="sub-header mt-5 mb-3">
              <h2>Kits</h2>
              <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back</Link>
              <CustomButton
                handleClick={handleModal}
                variant="primary"
              >Add New Kit
              </CustomButton>
            </div>
            <Table
              productStructure={productStructure}
              storeList={storeList}
              kitList={kitList}
              handleListChange={handleListChange}
              showConfirmDeleteModal={showConfirmDeleteModal}
              hideConfirmDeleteModal={hideConfirmDeleteModal}
              handleConfirmDeleteSubmit={handleConfirmDeleteSubmit}
              confirmDeleteModal={confirmDeleteModal}
              handleEdit={handleEdit}

            />
          </div>
        </Loader>
        <Model
          isOpen={isOpen}
          size={'xl'}
          hideModel={hideModel}
          productStructure={productStructure}
          storeList={storeList}
          handleMaterialFilterChange={handleMaterialFilterChange}
          handleLaborFilterChange={handleLaborFilterChange}
          handleFilter={handleFilter}
          mAddon={mAddon}
          lAddon={lAddon}
          laborCategories={laborCategories}
          otherProductStructure={otherProductStructure}
          handleChange={handleChange}
          handleMaterialSelect={handleMaterialSelect}
          handleLaborSelect={handleLaborSelect}
          kitMaterial={kitMaterial}
          kitLabor={kitLabor}
          handleSubmit={handleSubmit}
          state={state}
          createdMessage={createdMessage}
          editId={editId}
          validated={validated}
          isLoading={isLoading}
        />
      </MainWrapper>
    </Fragment>
  )
}

const mapStateToProps = ({ auth, ProductType, StoreInfo, kits, LaborCategory }) => {
  return {
    auth, ProductType, StoreInfo, kits, LaborCategory
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProductType: (token) => dispatch({ type: PRODUCT_TYPE_REQUEST, token }),
    fetchStoreInfo: (token) => dispatch({ type: STORE_INFO_REQUEST, token }),
    fetchMaterialAddons: (token, data) => dispatch({ type: MATERIAL_ADDONS_REQUEST, token, data }),
    fetchLaborAddons: (token, data) => dispatch({ type: LABOR_ADDONS_REQUEST, token, data }),
    fetchLaborCategory: (token) => dispatch({ type: LABOR_CATEGORY_REQUEST, token }),
    createKit: (data) => dispatch({ type: KIT_CREATE, data }),
    fetchKit: (token, data) => dispatch({ type: KIT_REQUEST, token, data }),
    fetchEditKit: (token, data) => dispatch({ type: KIT_EDIT_REQUEST, token, data }),
    fetchEditCreated: (token, data) => dispatch({ type: KIT_CREATED_REQUEST, token, data }),
    deleteKit: (data) => dispatch({ type: KIT_DELETE, data }),
    updateKit: (data) => dispatch({ type: KIT_UPDATE_REQUEST, data }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Kits);