import React, { Fragment, memo, useMemo, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import Input from "../../elements/CustomInput";
import InputFile from "../../elements/CustomFileInput";
import CustomCurrencyInput from "../../elements/CustomCurrencyInput";
import NumaricInput from "../../elements/NumaricInput";
import Select from "../../elements/CustomSelect";
import {
	CARPET_ID_DB,
  SHEET_VINYL_ID_DB,
  VINYL_PLANK_ID_DB,
  WALLBASE_ID_DB,
  INSTALLATION_ACCESSORIES_ID_DB,
  TRANSITION_ID_DB
	} from "../../../_utils/constants/ProductCatalog";
const FormElements = ({
  productStructure,
  units,
  storeList,
  state,
  handleChange,
  handleFileChange,
  vendorList,
  handleChangeCurrency,
  numberValidation,
  numberValidationCons,
  coverageNum,
}) => {
  const filterInput = useMemo(() => {
    if (state.ProductTypeId) {
      let projectType = productStructure.find(f => f.value === parseInt(state.ProductTypeId));
      if (projectType.value === CARPET_ID_DB) {
        return 1;
      } else if (projectType.value === SHEET_VINYL_ID_DB) {
        return 2;
      } else if (projectType.value === VINYL_PLANK_ID_DB) {
        return 3;
      } else if (projectType.value === INSTALLATION_ACCESSORIES_ID_DB) {
        return 4;
      } else if (projectType.value === WALLBASE_ID_DB) {
        return 5;
      } else if (projectType.value === TRANSITION_ID_DB) {
        return 6;
      } else {
        return 1
      }
    }

  }, [state.ProductTypeId])
  const handleValue = (value) => {
    if (value) {
      return value
    }
  }
  const [coverageUnitArr, setCoverageUnitArr] = useState([]);
  useMemo(() => {
    setCoverageUnitArr([
      {
        "value": 21,
        "label": "SY",

      },
      {
        "value": 24,
        "label": "SF",

      },
      {
        "value": 25,
        "label": "EA",

      },
      {
        "value": 26,
        "label": "LF",

      },
      {
        "value": 30,
        "label": "Roll",

      }
    ])
  }, [])
  return (
    <Fragment>
      <Row className="justify-content-center">
        <Col md={4} className="mb-3">
          <Select label={`Product Structure`} result={productStructure} ids="ProductTypeId" value={state.ProductTypeId} handleChange={handleChange} validate={true} />
        </Col>
      </Row>
      <Row className={` ${state.ProductTypeId ? null : 'd-none'}`}>
        <Col lg={4} md={6}>
          <Select label={`Store Name`} ids={`StoreId`} result={storeList} handleChange={handleChange} value={state.StoreId} validate={true} />
        </Col>
        <Col lg={4} md={6} className="mb-3">
          <InputFile label={`Photo`} ids={`Files`} title={state.PhotoPath} value={state.Files} validate={false} handleFileChange={handleFileChange} />

        </Col>
        <Col lg={4} md={6} className="mb-3">
          <Select label={`Vendor`} result={vendorList} ids="VendorId" handleChange={handleChange} value={state.VendorId} validate={false} />
        </Col>
        <Col lg={3} md={6} className="mb-3">
          <Input label={`Private Name`} ids={`PrivateName`} handleChange={handleChange} value={state.PrivateName} validate={false} />
        </Col>
        <Col lg={3} md={6} className="mb-3">
          <Input label={`Manufacturer`} ids={`Manufacturer`} handleChange={handleChange} value={state.Manufacturer} validate={true} />
        </Col>
        <Col lg={3} md={6} className="mb-3">
          <Input label={`Style`} ids={`Style`} handleChange={handleChange} value={state.Style} validate={true} />
        </Col>
        <Col lg={3} md={6} className="mb-3">
          <Input label={`Color`} ids={`Color`} handleChange={handleChange} value={state.Color} validate={true} />
        </Col>
        <Col lg={3} md={6} className="mb-3">
          <Select label={`Unit`} ids={`UnitId`} result={units} handleChange={handleChange} value={state.UnitId} validate={true} />
        </Col>
        {filterInput === 1 || filterInput === 2 || filterInput === 3 ? (
          <Fragment>
            <Col lg={3} md={6} className="mb-3">
              <NumaricInput label={`Length${filterInput === 3 ? " (in)" : " (ft)"}`} ids={`Length`} handleChange={handleChange} value={state.Length} validate={filterInput === 3 ? true : false} />
            </Col>
            <Col lg={3} md={6} className="mb-3">
              <Input label={`Width${filterInput === 3 ? " (in)" : " (ft)"}`} ids={`Width`} handleChange={handleChange} value={handleValue(state.Width)} validate={true} />
              {numberValidation ? (
                <span className="text-danger" fontSize={`12`}>{numberValidation}</span>
              ) : null}
            </Col>
          </Fragment>
        ) : null}

        {filterInput === 4 || filterInput === 5 || filterInput === 6 ? (
          <Fragment>
            <Col lg={3} md={6} className="mb-3">
              <Input label={`Coverage`} ids={`coverage`} handleChange={handleChange} value={state.coverage} validate={false} />

              {coverageNum ? (
                <span className="text-danger" fontSize={`12`}>{coverageNum}</span>
              ) : null}
            </Col>
            {filterInput !== 6 ? (
              <Col lg={3} md={6} className="mb-3">
                <Select label={`Coverage Unit`} ids={`coverageUnit`} result={coverageUnitArr} handleChange={handleChange} value={state.coverageUnit} validate={false} />
              </Col>
            ) : null}

            <Col lg={3} md={6} className="mb-3">
              <Select label={`Coverage Per Unit`} ids={`coveragePerUnit`} result={coverageUnitArr} handleChange={handleChange} value={state.coveragePerUnit} validate={false} />
            </Col>
          </Fragment>
        ) : null}
        {filterInput === 1 ? (
          <Fragment>
            <Col lg={3} md={6} className="mb-3">
              <NumaricInput label={`Face Weight`} ids={`FaceWidth`} handleChange={handleChange} value={state.FaceWidth} validate={true} />
            </Col>
            <Col lg={3} md={6} className="mb-3">
              <Select label={`Fiber Content`} result={[{ label: "PET", value: "PET" }, { label: "Nylon", value: "Nylon" }]} ids="Fiber" value={state.Fiber} handleChange={handleChange} validate={true} />
            </Col>
          </Fragment>
        ) : filterInput === 2 ? (
          <Col lg={3} md={6} className="mb-3">
            <NumaricInput label={`Guage`} ids={`Guage`} handleChange={handleChange} value={state.Guage} validate={true} />
          </Col>
        ) : filterInput === 3 ? (
          <Fragment>
            <Col lg={3} md={6} className="mb-3">
              <NumaricInput label={`Thickness`} ids={`Thickness`} handleChange={handleChange} value={state.Thickness} validate={true} />
            </Col>
            <Col lg={3} md={6} className="mb-3">
              <NumaricInput label={`Wear Layer`} ids={`wearLayer`} handleChange={handleChange} value={state.wearLayer} validate={true} />
            </Col>
            <Col lg={3} md={6} className="mb-3">
              <NumaricInput label={`Box QTY`} ids={`BoxQTY`} handleChange={handleChange} value={state.BoxQTY} validate={true} />
            </Col>
          </Fragment>
        ) : null}
        {filterInput === 2 ? (
          <Col lg={3} md={6} className="mb-3">
            <Select label={`Backing Type`} ids={`backingType`} result={[{ label: "Fiberglass", value: "Fiberglass" }, { label: "Felt", value: "Felt" }]} handleChange={handleChange} value={state.backingType} validate={false} />
          </Col>
        ) : null}
        <Col lg={3} md={6} className="mb-3">
          <Input label={`Manufacturer SKU`} ids={`ManufacturerSKU`} handleChange={handleChange} value={state.ManufacturerSKU} validate={true} />
        </Col>
        <Col lg={3} md={6} className="mb-3">
          <CustomCurrencyInput label={`Current Cost`} ids={`CurrentCost`} handleChange={handleChangeCurrency} value={handleValue(state.CurrentCost)} validate={true} />
        </Col>
        <Col lg={3} md={6} className="mb-3">
          <CustomCurrencyInput label={`Current Freight`} ids={`CurrentFreight`} handleChange={handleChangeCurrency} value={handleValue(state.CurrentFreight)} validate={true} />
          {numberValidationCons ? (
            <span className="text-danger" >{numberValidationCons}</span>
          ) : null}
        </Col>
      </Row>
    </Fragment>

  )
}


export default memo(FormElements);