export const CARPET_ID_DB = 132;
export const SHEET_VINYL_ID_DB = 133;
export const VINYL_PLANK_ID_DB = 158;
export const WALLBASE_ID_DB = 162;
export const INSTALLATION_ACCESSORIES_ID_DB = 163;
export const TRANSITION_ID_DB = 154;

export const UNIT_REQUEST = "@UNIT_REQUEST";
export const UNIT_SUCCESS = "@UNIT_SUCCESS";
export const UNIT_FAILED = "@UNIT_FAILED";

export const UNIT_CREATE = "@UNIT_CREATE";
export const UNIT_CREATE_SUCCESS = "@UNIT_CREATE_SUCCESS";
export const UNIT_CREATE_FAILED = "@UNIT_CREATE_FAILED";

export const UNIT_DELETE = "@UNIT_DELETE";
export const UNIT_DELETE_SUCCESS = "@UNIT_DELETE_SUCCESS";
export const UNIT_DELETE_FAILED = "@UNIT_DELETE_FAILED";

export const UNIT_EDIT = "@UNIT_EDIT";
export const UNIT_EDIT_SUCCESS = "@UNIT_EDIT_SUCCESS";
export const UNIT_EDIT_FAILED = "@UNIT_EDIT_FAILED";

export const UNIT_STATE_CHANGE = "@UNIT_STATE_CHANGE";

export const CARRIER_REQUEST = "@CARRIER_REQUEST";
export const CARRIER_SUCCESS = "@CARRIER_SUCCESS";
export const CARRIER_FAILED = "@CARRIER_FAILED";

export const CARRIER_CREATE = "@CARRIER_CREATE";
export const CARRIER_CREATE_SUCCESS = "@CARRIER_CREATE_SUCCESS";
export const CARRIER_CREATE_FAILED = "@CARRIER_CREATE_FAILED";

export const CARRIER_DELETE = "@CARRIER_DELETE";
export const CARRIER_DELETE_SUCCESS = "@CARRIER_DELETE_SUCCESS";
export const CARRIER_DELETE_FAILED = "@CARRIER_DELETE_FAILED";

export const CARRIER_EDIT = "@CARRIER_EDIT";
export const CARRIER_EDIT_SUCCESS = "@CARRIER_EDIT_SUCCESS";
export const CARRIER_EDIT_FAILED = "@CARRIER_EDIT_FAILED";

export const CARRIER_STATE_CHANGE = "@CARRIER_STATE_CHANGE";

export const PRODUCT_SOURCE_REQUEST = "@PRODUCT_SOURCE_REQUEST";
export const PRODUCT_SOURCE_SUCCESS = "@PRODUCT_SOURCE_SUCCESS";
export const PRODUCT_SOURCE_FAILED = "@PRODUCT_SOURCE_FAILED";

export const PRODUCT_SOURCE_CREATE = "@PRODUCT_SOURCE_CREATE";
export const PRODUCT_SOURCE_CREATE_SUCCESS = "@PRODUCT_SOURCE_CREATE_SUCCESS";
export const PRODUCT_SOURCE_CREATE_FAILED = "@PRODUCT_SOURCE_CREATE_FAILED";

export const PRODUCT_SOURCE_DELETE = "@PRODUCT_SOURCE_DELETE";
export const PRODUCT_SOURCE_DELETE_SUCCESS = "@PRODUCT_SOURCE_DELETE_SUCCESS";
export const PRODUCT_SOURCE_DELETE_FAILED = "@PRODUCT_SOURCE_DELETE_FAILED";

export const PRODUCT_SOURCE_EDIT = "@PRODUCT_SOURCE_EDIT";
export const PRODUCT_SOURCE_EDIT_SUCCESS = "@PRODUCT_SOURCE_EDIT_SUCCESS";
export const PRODUCT_SOURCE_EDIT_FAILED = "@PRODUCT_SOURCE_EDIT_FAILED";

export const PRODUCT_SOURCE_STATE_CHANGE = "@PRODUCT_SOURCE_STATE_CHANGE";

export const PROPERTY_REQUEST = "@PROPERTY_REQUEST";
export const PROPERTY_SUCCESS = "@PROPERTY_SUCCESS";
export const PROPERTY_FAILED = "@PROPERTY_FAILED";

export const PROPERTY_CREATE = "@PROPERTY_CREATE";
export const PROPERTY_CREATE_SUCCESS = "@PROPERTY_CREATE_SUCCESS";
export const PROPERTY_CREATE_FAILED = "@PROPERTY_CREATE_FAILED";

export const PROPERTY_DELETE = "@PROPERTY_DELETE";
export const PROPERTY_DELETE_SUCCESS = "@PROPERTY_DELETE_SUCCESS";
export const PROPERTY_DELETE_FAILED = "@PROPERTY_DELETE_FAILED";

export const PROPERTY_EDIT = "@PROPERTY_EDIT";
export const PROPERTY_EDIT_SUCCESS = "@PROPERTY_EDIT_SUCCESS";
export const PROPERTY_EDIT_FAILED = "@PROPERTY_EDIT_FAILED";

export const PROPERTY_STATE_CHANGE = "@PROPERTY_STATE_CHANGE";

export const SHIP_REQUEST = "@SHIP_REQUEST";
export const SHIP_SUCCESS = "@SHIP_SUCCESS";
export const SHIP_FAILED = "@SHIP_FAILED";

export const SHIP_CREATE = "@SHIP_CREATE";
export const SHIP_CREATE_SUCCESS = "@SHIP_CREATE_SUCCESS";
export const SHIP_CREATE_FAILED = "@SHIP_CREATE_FAILED";

export const SHIP_DELETE = "@SHIP_DELETE";
export const SHIP_DELETE_SUCCESS = "@SHIP_DELETE_SUCCESS";
export const SHIP_DELETE_FAILED = "@SHIP_DELETE_FAILED";

export const SHIP_EDIT = "@SHIP_EDIT";
export const SHIP_EDIT_SUCCESS = "@SHIP_EDIT_SUCCESS";
export const SHIP_EDIT_FAILED = "@SHIP_EDIT_FAILED";

export const SHIP_STATE_CHANGE = "@SHIP_STATE_CHANGE";

export const SHIP_PAYMENT_REQUEST = "@SHIP_PAYMENT_REQUEST";
export const SHIP_PAYMENT_SUCCESS = "@SHIP_PAYMENT_SUCCESS";
export const SHIP_PAYMENT_FAILED = "@SHIP_PAYMENT_FAILED";

export const SHIP_PAYMENT_CREATE = "@SHIP_PAYMENT_CREATE";
export const SHIP_PAYMENT_CREATE_SUCCESS = "@SHIP_PAYMENT_CREATE_SUCCESS";
export const SHIP_PAYMENT_CREATE_FAILED = "@SHIP_PAYMENT_CREATE_FAILED";

export const SHIP_PAYMENT_DELETE = "@SHIP_PAYMENT_DELETE";
export const SHIP_PAYMENT_DELETE_SUCCESS = "@SHIP_PAYMENT_DELETE_SUCCESS";
export const SHIP_PAYMENT_DELETE_FAILED = "@SHIP_PAYMENT_DELETE_FAILED";

export const SHIP_PAYMENT_EDIT = "@SHIP_PAYMENT_EDIT";
export const SHIP_PAYMENT_EDIT_SUCCESS = "@SHIP_PAYMENT_EDIT_SUCCESS";
export const SHIP_PAYMENT_EDIT_FAILED = "@SHIP_PAYMENT_EDIT_FAILED";

export const SHIP_PAYMENT_STATE_CHANGE = "@SHIP_PAYMENT_STATE_CHANGE";


export const MANUFACTURER_REQUEST = "@MANUFACTURER_REQUEST";
export const MANUFACTURER_SUCCESS = "@MANUFACTURER_SUCCESS";
export const MANUFACTURER_FAILED = "@MANUFACTURER_FAILED";

export const MANUFACTURER_CREATE = "@MANUFACTURER_CREATE";
export const MANUFACTURER_CREATE_SUCCESS = "@MANUFACTURER_CREATE_SUCCESS";
export const MANUFACTURER_CREATE_FAILED = "@MANUFACTURER_CREATE_FAILED";

export const MANUFACTURER_DELETE = "@MANUFACTURER_DELETE";
export const MANUFACTURER_DELETE_SUCCESS = "@MANUFACTURER_DELETE_SUCCESS";
export const MANUFACTURER_DELETE_FAILED = "@MANUFACTURER_DELETE_FAILED";

export const MANUFACTURER_EDIT = "@MANUFACTURER_EDIT";
export const MANUFACTURER_EDIT_SUCCESS = "@MANUFACTURER_EDIT_SUCCESS";
export const MANUFACTURER_EDIT_FAILED = "@MANUFACTURER_EDIT_FAILED";

export const MANUFACTURER_STATE_CHANGE = "@MANUFACTURER_STATE_CHANGE";

export const PAYMENT_ACCOUNT_REQUEST = "@PAYMENT_ACCOUNT_REQUEST";
export const PAYMENT_ACCOUNT_SUCCESS = "@PAYMENT_ACCOUNT_SUCCESS";
export const PAYMENT_ACCOUNT_FAILED = "@PAYMENT_ACCOUNT_FAILED";

export const PAYMENT_ACCOUNT_CREATE = "@PAYMENT_ACCOUNT_CREATE";
export const PAYMENT_ACCOUNT_CREATE_SUCCESS = "@PAYMENT_ACCOUNT_CREATE_SUCCESS";
export const PAYMENT_ACCOUNT_CREATE_FAILED = "@PAYMENT_ACCOUNT_CREATE_FAILED";

export const PAYMENT_ACCOUNT_DELETE = "@PAYMENT_ACCOUNT_DELETE";
export const PAYMENT_ACCOUNT_DELETE_SUCCESS = "@PAYMENT_ACCOUNT_DELETE_SUCCESS";
export const PAYMENT_ACCOUNT_DELETE_FAILED = "@PAYMENT_ACCOUNT_DELETE_FAILED";

export const PAYMENT_ACCOUNT_EDIT = "@PAYMENT_ACCOUNT_EDIT";
export const PAYMENT_ACCOUNT_EDIT_SUCCESS = "@PAYMENT_ACCOUNT_EDIT_SUCCESS";
export const PAYMENT_ACCOUNT_EDIT_FAILED = "@PAYMENT_ACCOUNT_EDIT_FAILED";

export const PAYMENT_ACCOUNT_STATE_CHANGE = "@PAYMENT_ACCOUNT_STATE_CHANGE";


export const SOLD_BY_REQUEST = "@SOLD_BY_REQUEST";
export const SOLD_BY_SUCCESS = "@SOLD_BY_SUCCESS";
export const SOLD_BY_FAILED = "@SOLD_BY_FAILED";

export const SOLD_BY_CREATE = "@SOLD_BY_CREATE";
export const SOLD_BY_CREATE_SUCCESS = "@SOLD_BY_CREATE_SUCCESS";
export const SOLD_BY_CREATE_FAILED = "@SOLD_BY_CREATE_FAILED";

export const SOLD_BY_DELETE = "@SOLD_BY_DELETE";
export const SOLD_BY_DELETE_SUCCESS = "@SOLD_BY_DELETE_SUCCESS";
export const SOLD_BY_DELETE_FAILED = "@SOLD_BY_DELETE_FAILED";

export const SOLD_BY_EDIT = "@SOLD_BY_EDIT";
export const SOLD_BY_EDIT_SUCCESS = "@SOLD_BY_EDIT_SUCCESS";
export const SOLD_BY_EDIT_FAILED = "@SOLD_BY_EDIT_FAILED";

export const SOLD_BY_STATE_CHANGE = "@SOLD_BY_STATE_CHANGE";


export const PRODUCT_TYPE_REQUEST = "@PRODUCT_TYPE_REQUEST";
export const PRODUCT_TYPE_SUCCESS = "@PRODUCT_TYPE_SUCCESS";
export const PRODUCT_TYPE_FAILED = "@PRODUCT_TYPE_FAILED";

export const PRODUCT_TYPE_CREATE = "@PRODUCT_TYPE_CREATE";
export const PRODUCT_TYPE_CREATE_SUCCESS = "@PRODUCT_TYPE_CREATE_SUCCESS";
export const PRODUCT_TYPE_CREATE_FAILED = "@PRODUCT_TYPE_CREATE_FAILED";

export const PRODUCT_TYPE_DELETE = "@PRODUCT_TYPE_DELETE";
export const PRODUCT_TYPE_DELETE_SUCCESS = "@PRODUCT_TYPE_DELETE_SUCCESS";
export const PRODUCT_TYPE_DELETE_FAILED = "@PRODUCT_TYPE_DELETE_FAILED";

export const PRODUCT_TYPE_EDIT = "@PRODUCT_TYPE_EDIT";
export const PRODUCT_TYPE_EDIT_SUCCESS = "@PRODUCT_TYPE_EDIT_SUCCESS";
export const PRODUCT_TYPE_EDIT_FAILED = "@PRODUCT_TYPE_EDIT_FAILED";

export const PRODUCT_TYPE_STATE_CHANGE = "@PRODUCT_TYPE_STATE_CHANGE";

export const PRODUCT_PRICE_REQUEST = "@PRODUCT_PRICE_REQUEST";
export const PRODUCT_PRICE_SUCCESS = "@PRODUCT_PRICE_SUCCESS";
export const PRODUCT_PRICE_FAILED = "@PRODUCT_PRICE_FAILED";

export const PRODUCT_PRICE_CREATE = "@PRODUCT_PRICE_CREATE";
export const PRODUCT_PRICE_CREATE_SUCCESS = "@PRODUCT_PRICE_CREATE_SUCCESS";
export const PRODUCT_PRICE_CREATE_FAILED = "@PRODUCT_PRICE_CREATE_FAILED";

export const PRODUCT_PRICE_DELETE = "@PRODUCT_PRICE_DELETE";
export const PRODUCT_PRICE_DELETE_SUCCESS = "@PRODUCT_PRICE_DELETE_SUCCESS";
export const PRODUCT_PRICE_DELETE_FAILED = "@PRODUCT_PRICE_DELETE_FAILED";

export const PRODUCT_PRICE_EDIT = "@PRODUCT_PRICE_EDIT";
export const PRODUCT_PRICE_EDIT_SUCCESS = "@PRODUCT_PRICE_EDIT_SUCCESS";
export const PRODUCT_PRICE_EDIT_FAILED = "@PRODUCT_PRICE_EDIT_FAILED";

export const PRODUCT_PRICE_STATE_CHANGE = "@PRODUCT_PRICE_STATE_CHANGE";


export const PRODUCT_PRICE_DETAIL_REQUEST = "@PRODUCT_PRICE_DETAIL_REQUEST";
export const PRODUCT_PRICE_DETAIL_SUCCESS = "@PRODUCT_PRICE_DETAIL_SUCCESS";
export const PRODUCT_PRICE_DETAIL_FAILED = "@PRODUCT_PRICE_DETAIL_FAILED";

export const PRODUCT_PRICE_DETAIL_CREATE = "@PRODUCT_PRICE_DETAIL_CREATE";
export const PRODUCT_PRICE_DETAIL_CREATE_SUCCESS = "@PRODUCT_PRICE_DETAIL_CREATE_SUCCESS";
export const PRODUCT_PRICE_DETAIL_CREATE_FAILED = "@PRODUCT_PRICE_DETAIL_CREATE_FAILED";

export const PRODUCT_PRICE_DETAIL_DELETE = "@PRODUCT_PRICE_DETAIL_DELETE";
export const PRODUCT_PRICE_DETAIL_DELETE_SUCCESS = "@PRODUCT_PRICE_DETAIL_DELETE_SUCCESS";
export const PRODUCT_PRICE_DETAIL_DELETE_FAILED = "@PRODUCT_PRICE_DETAIL_DELETE_FAILED";

export const PRODUCT_PRICE_DETAIL_EDIT = "@PRODUCT_PRICE_DETAIL_EDIT";
export const PRODUCT_PRICE_DETAIL_EDIT_SUCCESS = "@PRODUCT_PRICE_DETAIL_EDIT_SUCCESS";
export const PRODUCT_PRICE_DETAIL_EDIT_FAILED = "@PRODUCT_PRICE_DETAIL_EDIT_FAILED";

export const PRODUCT_PRICE_DETAIL_STATE_CHANGE = "@PRODUCT_PRICE_DETAIL_STATE_CHANGE";


export const PRODUCT_INFO_REQUEST = "@PRODUCT_INFO_REQUEST";
export const PRODUCT_INFO_SUCCESS = "@PRODUCT_INFO_SUCCESS";
export const PRODUCT_INFO_FAILED = "@PRODUCT_INFO_FAILED";

export const PRODUCT_INFO_CREATE = "@PRODUCT_INFO_CREATE";
export const PRODUCT_INFO_CREATE_SUCCESS = "@PRODUCT_INFO_CREATE_SUCCESS";
export const PRODUCT_INFO_CREATE_FAILED = "@PRODUCT_INFO_CREATE_FAILED";

export const PRODUCT_INFO_DELETE = "@PRODUCT_INFO_DELETE";
export const PRODUCT_INFO_DELETE_SUCCESS = "@PRODUCT_INFO_DELETE_SUCCESS";
export const PRODUCT_INFO_DELETE_FAILED = "@PRODUCT_INFO_DELETE_FAILED";

export const PRODUCT_INFO_EDIT = "@PRODUCT_INFO_EDIT";
export const PRODUCT_INFO_EDIT_SUCCESS = "@PRODUCT_INFO_EDIT_SUCCESS";
export const PRODUCT_INFO_EDIT_FAILED = "@PRODUCT_INFO_EDIT_FAILED";

export const PRODUCT_INFO_STATE_CHANGE = "@PRODUCT_INFO_STATE_CHANGE";


export const PHONE_REQUEST = "@PHONE_REQUEST";
export const PHONE_SUCCESS = "@PHONE_SUCCESS";
export const PHONE_FAILED = "@PHONE_FAILED";

export const PHONE_CREATE = "@PHONE_CREATE";
export const PHONE_CREATE_SUCCESS = "@PHONE_CREATE_SUCCESS";
export const PHONE_CREATE_FAILED = "@PHONE_CREATE_FAILED";

export const PHONE_DELETE = "@PHONE_DELETE";
export const PHONE_DELETE_SUCCESS = "@PHONE_DELETE_SUCCESS";
export const PHONE_DELETE_FAILED = "@PHONE_DELETE_FAILED";

export const PHONE_EDIT = "@PHONE_EDIT";
export const PHONE_EDIT_SUCCESS = "@PHONE_EDIT_SUCCESS";
export const PHONE_EDIT_FAILED = "@PHONE_EDIT_FAILED";

export const PHONE_STATE_CHANGE = "@PHONE_STATE_CHANGE";

export const NOTE_REQUEST = "@NOTE_REQUEST";
export const NOTE_SUCCESS = "@NOTE_SUCCESS";
export const NOTE_FAILED = "@NOTE_FAILED";

export const NOTE_CREATE = "@NOTE_CREATE";
export const NOTE_CREATE_SUCCESS = "@NOTE_CREATE_SUCCESS";
export const NOTE_CREATE_FAILED = "@NOTE_CREATE_FAILED";

export const NOTE_DELETE = "@NOTE_DELETE";
export const NOTE_DELETE_SUCCESS = "@NOTE_DELETE_SUCCESS";
export const NOTE_DELETE_FAILED = "@NOTE_DELETE_FAILED";

export const NOTE_EDIT = "@NOTE_EDIT";
export const NOTE_EDIT_SUCCESS = "@NOTE_EDIT_SUCCESS";
export const NOTE_EDIT_FAILED = "@NOTE_EDIT_FAILED";

export const NOTE_STATE_CHANGE = "@NOTE_STATE_CHANGE";


export const CONTACTS_REQUEST = "@CONTACTS_REQUEST";
export const CONTACTS_SUCCESS = "@CONTACTS_SUCCESS";
export const CONTACTS_FAILED = "@CONTACTS_FAILED";

export const CONTACTS_CREATE = "@CONTACTS_CREATE";
export const CONTACTS_CREATE_SUCCESS = "@CONTACTS_CREATE_SUCCESS";
export const CONTACTS_CREATE_FAILED = "@CONTACTS_CREATE_FAILED";

export const CONTACTS_DELETE = "@CONTACTS_DELETE";
export const CONTACTS_DELETE_SUCCESS = "@CONTACTS_DELETE_SUCCESS";
export const CONTACTS_DELETE_FAILED = "@CONTACTS_DELETE_FAILED";

export const CONTACTS_EDIT = "@CONTACTS_EDIT";
export const CONTACTS_EDIT_SUCCESS = "@CONTACTS_EDIT_SUCCESS";
export const CONTACTS_EDIT_FAILED = "@CONTACTS_EDIT_FAILED";

export const CONTACTS_STATE_CHANGE = "@CONTACTS_STATE_CHANGE";

export const PRODUCT_SPEC_REQUEST = "@PRODUCT_SPEC_REQUEST";
export const PRODUCT_SPEC_SUCCESS = "@PRODUCT_SPEC_SUCCESS";
export const PRODUCT_SPEC_FAILED = "@PRODUCT_SPEC_FAILED";

export const PRODUCT_SPEC_CREATE = "@PRODUCT_SPEC_CREATE";
export const PRODUCT_SPEC_CREATE_SUCCESS = "@PRODUCT_SPEC_CREATE_SUCCESS";
export const PRODUCT_SPEC_CREATE_FAILED = "@PRODUCT_SPEC_CREATE_FAILED";

export const PRODUCT_SPEC_DELETE = "@PRODUCT_SPEC_DELETE";
export const PRODUCT_SPEC_DELETE_SUCCESS = "@PRODUCT_SPEC_DELETE_SUCCESS";
export const PRODUCT_SPEC_DELETE_FAILED = "@PRODUCT_SPEC_DELETE_FAILED";

export const PRODUCT_SPEC_EDIT = "@PRODUCT_SPEC_EDIT";
export const PRODUCT_SPEC_EDIT_SUCCESS = "@PRODUCT_SPEC_EDIT_SUCCESS";
export const PRODUCT_SPEC_EDIT_FAILED = "@PRODUCT_SPEC_EDIT_FAILED";

export const PRODUCT_SPEC_STATE_CHANGE = "@PRODUCT_SPEC_STATE_CHANGE";

export const PRODUCT_INSTALLED_PRICE_REQUEST = "@PRODUCT_INSTALLED_PRICE_REQUEST";
export const PRODUCT_INSTALLED_PRICE_SUCCESS = "@PRODUCT_INSTALLED_PRICE_SUCCESS";
export const PRODUCT_INSTALLED_PRICE_FAILED = "@PRODUCT_INSTALLED_PRICE_FAILED";

export const PRODUCT_INSTALLED_PRICE_CREATE = "@PRODUCT_INSTALLED_PRICE_CREATE";
export const PRODUCT_INSTALLED_PRICE_CREATE_SUCCESS = "@PRODUCT_INSTALLED_PRICE_CREATE_SUCCESS";
export const PRODUCT_INSTALLED_PRICE_CREATE_FAILED = "@PRODUCT_INSTALLED_PRICE_CREATE_FAILED";

export const PRODUCT_INSTALLED_PRICE_DELETE = "@PRODUCT_INSTALLED_PRICE_DELETE";
export const PRODUCT_INSTALLED_PRICE_DELETE_SUCCESS = "@PRODUCT_INSTALLED_PRICE_DELETE_SUCCESS";
export const PRODUCT_INSTALLED_PRICE_DELETE_FAILED = "@PRODUCT_INSTALLED_PRICE_DELETE_FAILED";

export const PRODUCT_INSTALLED_PRICE_EDIT = "@PRODUCT_INSTALLED_PRICE_EDIT";
export const PRODUCT_INSTALLED_PRICE_EDIT_SUCCESS = "@PRODUCT_INSTALLED_PRICE_EDIT_SUCCESS";
export const PRODUCT_INSTALLED_PRICE_EDIT_FAILED = "@PRODUCT_INSTALLED_PRICE_EDIT_FAILED";

export const PRODUCT_INSTALLED_PRICE_STATE_CHANGE = "@PRODUCT_INSTALLED_PRICE_STATE_CHANGE";


export const PRODUCT_REQUEST = "@PRODUCT_REQUEST";
export const PRODUCT_SUCCESS = "@PRODUCT_SUCCESS";
export const PRODUCT_FAILED = "@PRODUCT_FAILED";

export const PRODUCT_CREATE = "@PRODUCT_CREATE";
export const PRODUCT_CREATE_SUCCESS = "@PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAILED = "@PRODUCT_CREATE_FAILED";

export const PRODUCT_DELETE = "@PRODUCT_DELETE";
export const PRODUCT_DELETE_SUCCESS = "@PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAILED = "@PRODUCT_DELETE_FAILED";

export const PRODUCT_EDIT = "@PRODUCT_EDIT";
export const PRODUCT_EDIT_SUCCESS = "@PRODUCT_EDIT_SUCCESS";
export const PRODUCT_EDIT_FAILED = "@PRODUCT_EDIT_FAILED";

export const PRODUCT_STATE_CHANGE = "@PRODUCT_STATE_CHANGE";


export const PRODUCT_CATALOG_REQUEST = "@PRODUCT_CATALOG_REQUEST";
export const PRODUCT_CATALOG_SUCCESS = "@PRODUCT_CATALOG_SUCCESS";
export const PRODUCT_CATALOG_FAILED = "@PRODUCT_CATALOG_FAILED";

export const PRODUCT_CATALOG_CREATE = "@PRODUCT_CATALOG_CREATE";
export const PRODUCT_CATALOG_CREATE_SUCCESS = "@PRODUCT_CATALOG_CREATE_SUCCESS";
export const PRODUCT_CATALOG_CREATE_FAILED = "@PRODUCT_CATALOG_CREATE_FAILED";

export const PRODUCT_CATALOG_DELETE = "@PRODUCT_CATALOG_DELETE";
export const PRODUCT_CATALOG_DELETE_SUCCESS = "@PRODUCT_CATALOG_DELETE_SUCCESS";
export const PRODUCT_CATALOG_DELETE_FAILED = "@PRODUCT_CATALOG_DELETE_FAILED";

export const PRODUCT_CATALOG_EDIT = "@PRODUCT_CATALOG_EDIT";
export const PRODUCT_CATALOG_EDIT_SUCCESS = "@PRODUCT_CATALOG_EDIT_SUCCESS";
export const PRODUCT_CATALOG_EDIT_FAILED = "@PRODUCT_CATALOG_EDIT_FAILED";

export const PRODUCT_CATALOG_UPDATE = "@PRODUCT_CATALOG_UPDATE";
export const PRODUCT_CATALOG_UPDATE_SUCCESS = "@PRODUCT_CATALOG_UPDATE_SUCCESS";
export const PRODUCT_CATALOG_UPDATE_FAILED = "@PRODUCT_CATALOG_UPDATE_FAILED";

export const PRODUCT_CATALOG_COPY_PRODUCT = "@PRODUCT_CATALOG_COPY_PRODUCT";
export const PRODUCT_CATALOG_COPY_PRODUCT_SUCCESS = "@PRODUCT_CATALOG_COPY_PRODUCT_SUCCESS";
export const PRODUCT_CATALOG_COPY_PRODUCT_FAILED = "@PRODUCT_CATALOG_COPY_PRODUCT_FAILED";

export const PRODUCT_CATALOG_STATE_CHANGE = "@PRODUCT_CATALOG_STATE_CHANGE";