import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit } from 'react-icons-kit/md';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import Create from "./models/CreateStore";
import ImportCustomer from "./models/ImportCustomer";
import ConfirmDelete from "./models/ConfirmDelete";
import Edit from "./models/EditStore";
import { STORE_INFO_REQUEST, STORE_INFO_DELETE, STORE_INFO_CREATE, STORE_INFO_EDIT, STORE_INFO_STATE_CHANGE } from "../../../../_utils/constants/admin/StoreInfo";
import StateJson from "../../../../_utils/states_titlecase.json";
import Loader from "../../../layouts/Loader";
import { ToastContainer, toast } from 'react-toastify';

const StoreList = ({
  auth: { auth, isAuthenticated },
  StoreInfo: { storeInfo, isEdited, isLoading, isCreated, isDeleted, isDataImported },
  fetchStoreInfo,
  submitStoreInfoCreate,
  submitStoreInfoDelete,
  submitStoreInfoEdit,
  changeReducerState,
}) => {

  // check for user authentication
  if (!isAuthenticated) return <Navigate to="/login" />;

  // states declarations
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [deleteStoreId, setDeleteStoreId] = useState('');
  const [importStoreId, setImportStoreId] = useState('');
  const [show, setShow] = useState(false);
  const [ids, setIds] = useState("");
  const [stateVal, setStateVal] = useState([]);
  const [validated, setValidated] = useState(false);
  const [cErrors, setCErrors] = useState({})
  const [state, setState] = useState({
    "storeName": "",
    "address1": "",
    "address2": "",
    "city": "",
    "state": "",
    "zipcode": "",
    "phone1": "",
    "phone2": "",
    "faxno": "",
  })

  // function called for setting state values
  const handleStateChange = (data) => {
    setStateVal(data);
  }

  // use effect function called for authentication
  useEffect(() => {
    if (isAuthenticated) {
      fetchStoreInfo(auth[0].token);
    }
  }, [auth, isAuthenticated]);

  // function to open create modal
  const showCreateModal = () => {
    setCreateModalOpen(true);
    setValidated(false);
  };

  // function to close create modal
  const hideCreateModal = () => {
    setCreateModalOpen(false);
    setShow(false)
    setIds("");
    setState({
      "storeName": "",
      "address1": "",
      "address2": "",
      "city": "",
      "state": "",
      "zipcode": "",
      "phone1": "",
      "phone2": "",
      "faxno": "",
    })
    setValidated(false);
  };

  // function to open import customer modal
  const showImportModal = (id) => {
    setImportStoreId(id);
    setImportModalOpen(true);
  };

  // function to close import customer modal
  const hideImportModal = () => {
    setImportStoreId('');
    setImportModalOpen(false);
    changeReducerState();
  };

  // use effect function called after api success
  useEffect(() => {
    if (isCreated === true) {
      toast.success("Store information created successfully.");
      fetchStoreInfo(auth[0].token);
      changeReducerState();
    }
    if (isDeleted === true) {
      toast.success("Store information deleted successfully.");
      fetchStoreInfo(auth[0].token);
      changeReducerState();
    }
    if (isEdited === true) {
      toast.success("Store information updated successfully.");
      fetchStoreInfo(auth[0].token);
      changeReducerState();
    }
    if (isDataImported === true) {
      // hideImportModal();
      toast.success("Customers data imported successfully.");
      // fetchStoreInfo(auth[0].token);
      // changeReducerState();
    }
    setValidated(false);
  }, [isCreated, isDeleted, isEdited, isDataImported]);

  // function called for value change
  const handleChange = (e) => {
    const { id, value } = e.target;
    setState({ ...state, [id]: value })
  }

  // function called for create sales stage create api
  const handleCreateModalSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      submitStoreInfoCreate({
        "data": {
          "storeName": state.storeName,
          "address1": state.address1,
          "address2": state.address2,
          "city": state.city,
          "state": stateVal ? stateVal.label : "",
          "zipcode": state.zipcode,
          "phone1": state.phone1,
          "phone2": state.phone2,
          "faxno": state.faxno,
          "CreatedBy": auth[0].userId
        },
        "token": auth[0].token
      });
    }
    setValidated(true);
    if (form.checkValidity() !== false) {
      hideCreateModal();
    }
  }

  // function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteStoreId(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteStoreId('');
    setConfirmDeleteModal(false);
  };

  // function called for delete sales stage delete api
  const handleConfirmDeleteSubmit = () => {
    submitStoreInfoDelete({
      "StoreId": deleteStoreId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }

  // function called on edit icon click
  const handleEditClick = (data) => {
    setStateVal(StateJson.find(d => d.label === data.state))
    setState(data);
    setIds(data.storeId);
    setShow(true)
  }

  // function called to save edited data
  const handleEditSaveClick = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      submitStoreInfoEdit({
        "data": {
          "StoreId": ids,
          "storeName": state.storeName,
          "address1": state.address1,
          "address2": state.address2,
          "city": state.city,
          "state": stateVal ? stateVal.label : "",
          "zipcode": state.zipcode,
          "phone1": state.phone1,
          "phone2": state.phone2,
          "faxno": state.faxno,
        },
        "token": auth[0].token
      });
    }
    setValidated(true);
    if (form.checkValidity() !== false) {
      hideCreateModal();
    }
  }

  return (
    <MainWrapper>
      <Loader loading={isLoading}>
        <div className="container">
          <ToastContainer />
          <div className="sub-header mt-5 mb-3">
            <h2>Store Listing</h2>
            <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back</Link>
            <Create
              variant="primary"
              title={`StoreInfo`}
              createModalOpen={createModalOpen}
              showCreateModal={showCreateModal}
              hideCreateModal={hideCreateModal}
              handleCreateModalSubmit={handleCreateModalSubmit}
              handleChange={handleChange}
              state={state}
              StateJson={StateJson}
              handleStateChange={handleStateChange}
              stateVal={stateVal}
              validated={validated}
              setCErrors={setCErrors}
              cErrors={cErrors}
            />
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>Store Name</th>
                <th>Address</th>
                <th>City</th>
                <th>Phone</th>
                <th>Fax</th>
                <th width="50" className="actions">
                  <div className="d-flex">
                    <span>Edit</span>
                    <span>Delete</span>
                    <span>Import</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {storeInfo && storeInfo.length > 0 ? (
                storeInfo.map((item, index) => (
                  <tr key={index}>
                    <td> {item.storeName}</td>
                    <td>
                      {item.address1} <br />{item.address2}
                    </td>
                    <td> {item.city}</td>
                    <td> {item.phone1 ? item.phone1 + "," + item.phone2 : item.phone1}</td>
                    <td> {item.faxno}</td>
                    <td className="actions">
                      <div className="btn-group">
                        <Icon role="button" className="w-54 d-flex justify-content-center" size={18} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
                        <ConfirmDelete variant="primary" title={`Store Information`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.storeId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
                        <ImportCustomer
                          importModalOpen={importModalOpen}
                          showImportModal={()=>showImportModal(item.storeId)}
                          hideImportModal={hideImportModal}
                          importStoreId={importStoreId}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    No Data Found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Loader>
      <Edit
        state={state}
        handleChange={handleChange}
        handleEditSaveClick={handleEditSaveClick}
        show={show}
        hideCreateModal={hideCreateModal}
        StateJson={StateJson}
        handleStateChange={handleStateChange}
        stateVal={stateVal}
        validated={validated}
        setCErrors={setCErrors}
        cErrors={cErrors}
      />
    </MainWrapper>
  )
}

const mapStateToProps = ({ auth, StoreInfo }) => {
  return {
    auth,
    StoreInfo
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchStoreInfo: (token) => dispatch({ type: STORE_INFO_REQUEST, token }),
    submitStoreInfoCreate: (data) => dispatch({ type: STORE_INFO_CREATE, payload: data }),
    submitStoreInfoDelete: (data) => dispatch({ type: STORE_INFO_DELETE, payload: data }),
    submitStoreInfoEdit: (data) => dispatch({ type: STORE_INFO_EDIT, payload: data }),
    changeReducerState: () => dispatch({ type: STORE_INFO_STATE_CHANGE }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StoreList);