import React, { Fragment, useState, useMemo, useEffect } from "react";
import '../../../assets/css/Contacts.css';
import _ from "lodash";
import { Table } from "react-bootstrap";
import Input from "../../elements/CustomInput";
import Select from "../../elements/CustomSelect";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";

const CustomerList = ({
	customerList,
	totalCount,
	setPaginate,
	paginate,
	listParamState,
  handleFilterChange,
	storeList
}) => {

	// states declaration
  const [items, setItems] = useState([]);
  const [isMore, setIsMore] = useState([]);

	// function called to fetch more data, pagination
	const fetchMoreData = () => {
    setTimeout(() => {
      setPaginate(paginate + 10)
    }, 1500);
  };

	// function to check pagination mor to load data or not
  useEffect(() => {
    setItems(customerList);
		if (_.isEqual(totalCount, customerList.length)) {
      setIsMore(false);
    } else {
      setIsMore(true);
    }
  }, [customerList])

	// setting new list data using memo
	const data = useMemo(() => items, [items]);

	// setting store list data using memo
	const storeData = useMemo(() => {
    var stores = [{ value: 0, label: "All" }];
    return stores.concat(storeList);
  }, [storeList])

	return (
		<Fragment>
      <Table bordered responsive>
        <InfiniteScroll
          dataLength={data.length}
          next={fetchMoreData}
          hasMore={isMore}
          loader={
            <div className="data-loading">
              <img src="https://64.media.tumblr.com/695ce9a82c8974ccbbfc7cad40020c62/tumblr_o9c9rnRZNY1qbmm1co1_1280.gifv" />
              <span>Load More</span>
            </div>
          }>
          <thead>
            <tr>
							<th>
                <Input label={`Salesperson`} ids={`salesperson`} value={listParamState.salesperson} validate={false} handleChange={handleFilterChange} />
              </th>
              <th>
                <Input label={`Property Name`} ids={`propertyName`} value={listParamState.propertyName} validate={false} handleChange={handleFilterChange} />
              </th>
              <th>
                <Input label={`Management Company`} ids={`propertyManagerName`} value={listParamState.propertyManagerName} validate={false} handleChange={handleFilterChange} />
              </th>
              <th>
                <Input label={`Address`} ids={`propertyAddress`} value={listParamState.propertyAddress} validate={false} handleChange={handleFilterChange} />
              </th>
              <th>
                <Input label={`City`} ids={`city`} value={listParamState.city} validate={false} handleChange={handleFilterChange} />
              </th>
              <th>
                <Input label={`State`} ids={`state`} value={listParamState.state} validate={false} handleChange={handleFilterChange} />
              </th>
              <th>
                <Select label={`Store Name`} result={storeData} ids="StoreId" value={listParamState.StoreId} handleChange={handleFilterChange} validate={false} />
              </th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(data) ? _.map(data, (cust) => {
              return (
                <tr key={cust.importCustomerId}>
									<td>{_.get(cust, "salesperson", "")}</td>
                  <td>
                    <Link to={`customer-detail/${cust.importCustomerId}`}>{_.get(cust, "propertyName", "")}</Link>
                  </td>
                  <td>{_.get(cust, "propertyManagerName", "")}</td>
                  <td>{_.get(cust, "propertyAddress", "")}</td>
                  <td>{_.get(cust, "city", "")}</td>
                  <td>{_.get(cust, "state", "")}</td>
                  <td>{_.get(cust, "storeName", "")}</td>
                </tr>
              )
            }) : (
              <tr>
                <td colSpan={`18`}>No Data Found</td>
              </tr>
            )}
          </tbody>
        </InfiniteScroll>
      </Table>
		</Fragment>
	)
}

export default CustomerList;