import React from "react";
import Model from "../../elements/CustomModel";
import ModelHeader from "../../elements/ModelHeader";
import ModelBody from "../../elements/ModelBody";
import ModelFooter from "../../elements/ModelFooter";
import Button from "../../elements/CustomButton";
import Form from "../../elements/CustomForm";
import FormElements from "./FormElements";
const CreateProductCatalog = (
  {
    isOpen,
    size,
    hideModel,
    productStructure,
    units,
    storeList,
    state,
    handleChange,
    handleSubmit,
    validated,
    handleFileChange,
    vendorList,
    handleChangeCurrency,
    numberValidation,
    numberValidationCons,
    coverageNum,
    isLoading,
    ids
  }
) => {
  return (
    <Model isOpen={isOpen} size={size}>
      <ModelHeader>{ids ? 'Edit ' : 'Add New '}Product</ModelHeader>
      <Form handleSubmit={handleSubmit} validated={validated}>
        <ModelBody>
          <FormElements
            productStructure={productStructure}
            units={units}
            storeList={storeList}
            handleChange={handleChange}
            state={state}
            handleFileChange={handleFileChange}
            vendorList={vendorList}
            handleChangeCurrency={handleChangeCurrency}
            numberValidation={numberValidation}
            numberValidationCons={numberValidationCons}
            coverageNum={coverageNum}

          />
        </ModelBody>
        <ModelFooter>
          <Button handleClick={hideModel} variant="secondary" type="button">
            Close
          </Button>
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? (<div className="spinner-border"></div>) : "Save"}
          </Button>
        </ModelFooter>
      </Form>
    </Model>
  )
}


export default React.memo(CreateProductCatalog);