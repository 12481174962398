import React, { useState, useEffect, useMemo } from 'react';
import '../../assets/css/auth.css';
import { Form, Button } from 'react-bootstrap';
import { Link, Navigate } from "react-router-dom";
import { connect } from 'react-redux';
import { LOGIN_USER_REQUEST, ORDER_USER_REQUEST, AUTH_STATE_CHANGE } from "../../_utils/constants/Auth";
//import { encrypted } from "../../_utils/helpers";
import _ from 'lodash';
import { toast, ToastContainer } from 'react-toastify';

const Login = ({
  auth: { isAuthenticated, errors: { errors }, isLoading, isSessionExpired },
  handleLogin, handleCookie, changeState
}) => {

  const [loading, setLoading] = useState(isLoading);
  const [state, setState] = useState({
    EmailId: "",
    Password: "",
    StoreId: "1"
  });

  const handleChange = ({ target: { id, value } }) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    handleLogin(state);
  }
  useEffect(() => {
    handleCookie("orderingLogin")
  }, [])


  useMemo(() => {
    if (isAuthenticated) {
      setState({
        EmailId: "", Password: "", StoreId: "1"
      });
    }
    if (isSessionExpired) {
      toast.error("Session Expired!! Please login again.");
    }
  }, [isAuthenticated, isSessionExpired]);
  useMemo(() => {
    if (errors?.emailId) {

      toast.error(`${errors?.emailId[0]}`);
      changeState()
      setLoading(false)
    }
  }, [errors])
  useMemo(() => {
    if (!_.isEmpty(errors)) {
      setLoading(false)
    }
  }, [errors])
  if (isAuthenticated) return <Navigate to="/dashboard" />;

  return (
    <div className="login-wrapper">

      <div className="login-container">
        <ToastContainer />
        <div className="login-left">
          <div className="top">
            <h2 className="sarina">Geoff ERP</h2>
          </div>
          <div className="bottom">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ut commodo ex. Sed id nulla eget est vulputate placerat eu et urna.</p>
          </div>
        </div>
        <div className="login-right">
          <Form onSubmit={handleSubmit} noValidate>
            <h3 className="title">Login</h3>
            <Form.Group className="mb-3" controlId="EmailId">
              <Form.Control type="email" placeholder="Enter email" onChange={handleChange} isInvalid={errors ? !_.isUndefined(errors.EmailId) : false} />
              <Form.Control.Feedback type={!_.isEmpty(errors) ? !_.isUndefined(errors.EmailId) ? 'invalid' : "" : ""}>
                {!_.isEmpty(errors) ? _.get(errors, 'EmailId', '') : ""}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="Password">
              <Form.Control type="password" placeholder="Password" onChange={handleChange} isInvalid={errors ? !_.isUndefined(errors.Password) : false} />
              <Form.Control.Feedback type={!_.isEmpty(errors) ? !_.isUndefined(errors.Password) ? 'invalid' : "" : ""}>
                {!_.isEmpty(errors) ? _.get(errors, 'Password', '') : ""}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 d-flex align-items-center" controlId="formBasicCheckbox">
              <Button variant="primary" type="submit">
                {loading ? (<div className="spinner-border"></div>) : "Login"}
              </Button>
              <Link className="ms-auto" to="/forgot-password">Forgot Password?</Link>
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth }) => {
  return {
    auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleLogin: (data) => dispatch({ type: LOGIN_USER_REQUEST, data }),
    handleCookie: (data) => dispatch({ type: ORDER_USER_REQUEST, data }),
    changeState: () => dispatch({ type: AUTH_STATE_CHANGE })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

