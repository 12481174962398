import React from "react";
import { Routes, Route } from "react-router-dom";
import Unit from "../components/pages/admin/productcatalog/Unit";
import Carrier from "../components/pages/admin/productcatalog/Carrier";
import ProductSource from "../components/pages/admin/productcatalog/ProductSource";
import Property from "../components/pages/admin/productcatalog/Property";
import Ship from "../components/pages/admin/productcatalog/Ship";
import ShipPayment from "../components/pages/admin/productcatalog/ShipPayment";
import Manufacturer from "../components/pages/admin/productcatalog/Manufacturer";
import PaymentAccount from "../components/pages/admin/productcatalog/PaymentAccount";
import SoldBy from "../components/pages/admin/productcatalog/SoldBy";
import ProductType from "../components/pages/admin/productcatalog/ProductType";
import ProductPrice from "../components/pages/admin/productcatalog/ProductPrice";
import ProductPriceDetail from "../components/pages/admin/productcatalog/ProductPriceDetail";
import ProductInfo from "../components/pages/admin/productcatalog/ProductInfo";
import Phone from "../components/pages/admin/productcatalog/Phone";
import Note from "../components/pages/admin/productcatalog/Note";
import Contacts from "../components/pages/admin/productcatalog/Contacts";
import ProductSpec from "../components/pages/admin/productcatalog/ProductSpec";
import ProductInstalledPrice from "../components/pages/admin/productcatalog/ProductInstalledPrice";
import Product from "../components/pages/admin/productcatalog/Product";
import LaborCategory from "../components/pages/admin/labor/category/List";
import LaborItems from "../components/pages/admin/labor/items/List";
import Kits from "../components/pages/admin/kits/List";
const ProductRoute = () => {
  return (
    <Routes>
      <Route path="/product-catalog/unit" exact element={<Unit />} />
      <Route path="/product-catalog/carrier" exact element={<Carrier />} />
      <Route path="/product-catalog/productsource" exact element={<ProductSource />} />
      <Route path="/product-catalog/property" exact element={<Property />} />
      <Route path="/product-catalog/ship" exact element={<Ship />} />
      <Route path="/product-catalog/ship-payment" exact element={<ShipPayment />} />
      <Route path="/product-catalog/manufacturer" exact element={<Manufacturer />} />
      <Route path="/product-catalog/payment-account" exact element={<PaymentAccount />} />
      <Route path="/product-catalog/sold-by" exact element={<SoldBy />} />
      <Route path="/product-type" exact element={<ProductType />} />
      <Route path="/product-catalog/product-price" exact element={<ProductPrice />} />
      <Route path="/product-catalog/product-price-detail" exact element={<ProductPriceDetail />} />
      <Route path="/product-catalog/product-info" exact element={<ProductInfo />} />
      <Route path="/product-catalog/phone" exact element={<Phone />} />
      <Route path="/product-catalog/note" exact element={<Note />} />
      <Route path="/product-catalog/contacts" exact element={<Contacts />} />
      <Route path="/product-catalog/product-spec" exact element={<ProductSpec />} />
      <Route path="/product-catalog/product-installed-price" exact element={<ProductInstalledPrice />} />
      <Route path="/product-catalog/product" exact element={<Product />} />
      <Route path="/admin/labor/catgeory" exact element={<LaborCategory />} />
      <Route path="/admin/labor/items" exact element={<LaborItems />} />
      <Route path="/kits" exact element={<Kits />} />
    </Routes>
  );
};

export default ProductRoute;