import React from "react";
import MainWrapper from "../../MainWrapper";
import Title from "../../elements/Title";
import { Icon } from "react-icons-kit";
import { ic_arrow_back } from "react-icons-kit/md";
import { Link, useParams } from "react-router-dom";

const CustomerDetail = () => {

	const { id } = useParams();

	return (
		<MainWrapper>
			<div className="container">
				<div className="sub-header mt-5 mb-3">
					<Title>Customer Detail</Title>
					<Link className="btn btn-primary me-1" to="/customer-list"><Icon icon={ic_arrow_back} />Back</Link>
				</div>
				<div>Customer details page for user id - {id}</div>
			</div>
		</MainWrapper>
	)
}

export default CustomerDetail;