import React, { Fragment, useState, useMemo, useEffect } from "react";
import { Table } from 'react-bootstrap';
import Icon from "react-icons-kit";
import { ic_mode_edit } from 'react-icons-kit/md';
import ConfirmDelete from "../../../../elements/ConfirmDelete";
import _ from "lodash";
import Select from "../../../../elements/CustomSelect";
import Input from "../../../../elements/CustomInput";
import CustomCurrencyInput from "../../../../elements/CustomCurrencyInput";
import DateInput from "../../../../elements/DateInput";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
const ItemTable = ({
  paginate,
  handleEdit,
  laborItem,
  showConfirmDeleteModal,
  hideConfirmDeleteModal,
  handleConfirmDeleteSubmit,
  confirmDeleteModal,
  stateFilter,
  handleFilterChange,
  productStructure,
  units,
  storeList,
  laborCategories,
  handleFilterChangeCurrency,
  handleCurrencyUpdateChange,
  updateState,
  product_id,
  handleUpdateChange,
  setPaginate,
  totalCount
}) => {
  const [items, setItems] = useState([]);
  const [isMore, setIsMore] = useState([]);
  const fetchMoreData = () => {
    setTimeout(() => {
      setPaginate(paginate + 5)
    }, 1500);
  };
  const productData = useMemo(() => {
    var productType = [{ value: 0, label: "All" }];
    return productType.concat(productStructure);
  }, [productStructure])
  const storeData = useMemo(() => {
    var stores = [{ value: 0, label: "All" }];
    return stores.concat(storeList);
  }, [storeList])
  const laborData = useMemo(() => {
    var laborCategory = [{ value: 0, label: "All" }];
    return laborCategory.concat(laborCategories);
  }, [laborCategories])
  const unitData = useMemo(() => {
    var unit = [{ value: 0, label: "All" }];
    return unit.concat(units);
  }, [units])

  useEffect(() => {
    setItems(laborItem)
  }, [laborItem])
  useEffect(() => {
    if (_.isEqual(totalCount, laborItem.length)) {
      setIsMore(false)
    } else {
      setIsMore(true);
    }
  }, [laborItem])
  const data = useMemo(() => items, [items]);
  const handleValue = (value) => {
    if (value) {
      return value
    }
  }
  const handleValue1 = (value) => {
    if (value) {
      return parseFloat(value).toFixed(2)
    }
  }
  return (
    <Fragment>
      <Table responsive bordered>
        <InfiniteScroll
          dataLength={data.length}
          next={fetchMoreData}
          hasMore={isMore}
          loader={
            <div className="data-loading">
              <img src="https://64.media.tumblr.com/695ce9a82c8974ccbbfc7cad40020c62/tumblr_o9c9rnRZNY1qbmm1co1_1280.gifv" />
              <span>Load More</span>
            </div>
          }>
          <thead>
            <tr>
              <th>
                <Select label={`Store Name`} result={storeData} ids="storeId" value={stateFilter.storeId} handleChange={handleFilterChange} validate={false} />
              </th>
              <th>
                <Select label={`Product Structure`} result={productData} ids="productStructureId" value={stateFilter.productStructureId} handleChange={handleFilterChange} validate={false} />
              </th>
              <th>
                <Select label={`Labor Category`} ids={`laborCategoryId`} result={laborData} handleChange={handleFilterChange} value={stateFilter.laborCategoryId} validate={false} />
              </th>
              <th>
                <Input label={"Labor Name"} ids={'laborName'} handleChange={handleFilterChange} value={stateFilter.laborName} validate={false} />
              </th>
              <th>
                <Select label={`Unit`} ids={`unitId`} result={unitData} handleChange={handleFilterChange} value={stateFilter.unitId} validate={false} />
              </th>
              <th>
                <CustomCurrencyInput label={`Current Cost`} ids={`currentCost`} handleChange={handleFilterChangeCurrency} value={stateFilter.currentCost} validate={false} />
              </th>
              <th>
                <CustomCurrencyInput label={`Future Cost 1`} value={stateFilter.futureCost1} ids={`futureCost1`} validate={false} handleChange={handleFilterChangeCurrency} />
              </th>
              <th>
                <DateInput label={`Increase Date`} value={stateFilter.increaseDate} ids={`increaseDate`} validate={false} handleChange={handleFilterChange} />
              </th>
              <th width="50" className="actions">
                <div className="d-flex">
                  <span>Edit</span>
                  <span>Delete</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(data) ? _.map(data, (item) => (
              <tr key={item.laborItemId}>
                <td>{item.storeName}</td>
                <td>{item.productType}</td>
                <td>{item.laborCategory}</td>
                <td>{item.laborName}</td>
                <td>{item.unitName}</td>

                <td>
                  <CustomCurrencyInput ids={`currentCost`} validate={false} handleChange={(value, name) => handleCurrencyUpdateChange(value, name, item)} value={(parseInt(product_id) === item.laborItemId) ? handleValue(updateState.currentCost) : handleValue1(item.currentCost)} />

                </td>
                <td>
                  <CustomCurrencyInput ids={`futureCost1`} validate={false} handleChange={(value, name) => handleCurrencyUpdateChange(value, name, item)} value={(parseInt(product_id) === item.laborItemId) ? handleValue(updateState.futureCost1) : handleValue1(item.futureCost1)} />
                </td>
                <td>
                  <DateInput ids={`increaseDate`} validate={false} handleChange={(e) => handleUpdateChange(e, item)} value={(parseInt(product_id) === item.laborItemId) ? moment(new Date(`${updateState.increaseDate}`)).format("YYYY-MM-DD") : moment(new Date(`${item.increaseDate}`)).format("YYYY-MM-DD")} />
                </td>
                <td>
                  <div className="btn-group">
                    <Icon role="button" className="w-54 d-flex justify-content-center" size={18} icon={ic_mode_edit} onClick={(e) => handleEdit(e, item)} />
                    <ConfirmDelete
                      variant="primary"
                      title={`Labor Items`}
                      showConfirmDeleteModal={() => showConfirmDeleteModal(item.laborItemId)}
                      hideConfirmDeleteModal={hideConfirmDeleteModal}
                      handleConfirmDeleteSubmit={handleConfirmDeleteSubmit}
                      confirmDeleteModal={confirmDeleteModal}
                    />
                  </div>
                </td>
              </tr>
            )) : (
              <tr>
                <td colSpan={`7`} className="text-center">No Item Found</td>
              </tr>
            )}
          </tbody>
        </InfiniteScroll>
      </Table>
    </Fragment>
  )
}


export default ItemTable;