import React from "react";
import { Routes, Route } from "react-router-dom";
import StoreList from "../components/pages/admin/storeinfo/StoreList";

const AdminRoute = () => {
  return (
    <Routes>
      <Route path="/admin/product-catalog/store-list" exact element={<StoreList />} />
    </Routes>
  );
};

export default AdminRoute;