import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
	CUSTOMER_LIST_REQUEST,
	CUSTOMER_LIST_SUCCESS,
	CUSTOMER_LIST_FAILED
} from "../../../_utils/constants/Customer";

function* fetchCustomerList({ token, sort, page, paginate, listParamState }) {
	try {
		const response = yield call(axios.get, `${API_URL}/User/api/CustomerInfo/GetCustomerInfo?PageIndex=${page}&PageSize=${paginate}&sort=${sort}${listParamState.sortOn ? '&sortOn=' + listParamState.sortOn : ""}${listParamState.propertyName ? '&PropertyName=' + listParamState.propertyName : ""}${listParamState.propertyManagerName ? '&PropertyManagerName=' + listParamState.propertyManagerName : ""}${listParamState.propertyAddress ? '&PropertyAddress=' + listParamState.propertyAddress : ""}${listParamState.state ? '&State=' + listParamState.state : ""}${listParamState.city ? '&City=' + listParamState.city : ""}${listParamState.StoreId ? '&StoreId=' + listParamState.StoreId : ""}`, { headers: { Authorization: `Bearer ${token}` } });
		yield put({ type: CUSTOMER_LIST_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_LIST_FAILED, payload: error.response });
		}
	}
}

export default function* rootCustomerInfo() {
	yield all([
		takeLatest(CUSTOMER_LIST_REQUEST, fetchCustomerList),
	])
}