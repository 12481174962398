import React from "react";
import { Routes, Route } from "react-router-dom";
import Customer from "../components/pages/customer/Customer";
import CustomerDetail from "../components/pages/customer/CustomerDetail";

const CustomerRoute = () => {
  return (
    <Routes>
      <Route path="/customer-list" exact element={<Customer />} />
      <Route path="/customer-list/customer-detail/:id" exact element={<CustomerDetail />} />
    </Routes>
  );
};

export default CustomerRoute;