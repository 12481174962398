import React, { Fragment, useMemo } from "react";
import Model from "../../../elements/CustomModel";
import ModelHeader from "../../../elements/ModelHeader";
import ModelBody from "../../../elements/ModelBody";
import ModelFooter from "../../../elements/ModelFooter";
import Form from "../../../elements/CustomForm";
import Select from "../../../elements/CustomSelect";
import Input from "../../../elements/CustomInput";
import Button from "../../../elements/CustomButton";
import Checkbox from "../../../elements/CustomCheckBox";
import { Col, Row } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
// import TableScrollbar from "../../../TableScrollbar";
const AddEdit = ({
  isOpen,
  size,
  hideModel,
  productStructure,
  storeList,
  mAddon,
  lAddon,
  laborCategories,
  otherProductStructure,
  handleChange,
  handleMaterialFilterChange,
  handleLaborFilterChange,
  handleMaterialSelect,
  kitMaterial,
  handleLaborSelect,
  kitLabor,
  handleSubmit,
  state,
  createdMessage,
  editId,
  validated,
  isLoading
}) => {
  const message = useMemo(() => createdMessage, [createdMessage])

  return (
    <Fragment>
      <Model isOpen={isOpen} size={size}>
        <ModelHeader>{editId ? 'Edit Kit' : "Add New Kit"} </ModelHeader>
        <Form handleSubmit={handleSubmit} validated={validated}>
          <ModelBody>
            <Row className="justify-content-center mb-3">
              <Col md={6} className="mb-3">
                <Select label={`Product Structure`} handleChange={handleChange} value={state.ProductTypeId} result={productStructure} ids="ProductTypeId" validate={true} />
              </Col>
              <Col md={6}>
                <Select label={`Store Name`} ids={`StoreId`} result={storeList} value={state.StoreId} handleChange={handleChange} validate={true} />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <h6>Material Add Ons</h6>
              <div className="gridtable">
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th>Check to Add</th>
                      <th>
                        <Select label={`Product Structure`} handleChange={handleMaterialFilterChange} result={otherProductStructure} ids="ProductTypeId" validate={false} />
                      </th>
                      <th>
                        <Input label={`Private Name`} ids="PrivateName" validate={false} handleChange={handleMaterialFilterChange} />
                      </th>
                      <th>
                        <Input label={`Manufacturer`} ids="Manufacturer" validate={false} handleChange={handleMaterialFilterChange} />
                      </th>
                      <th>
                        <Input label={`Style`} ids="Style" validate={false} handleChange={handleMaterialFilterChange} />
                      </th>
                      <th>
                        <Input label={`Color`} ids="Color" validate={false} handleChange={handleMaterialFilterChange} />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {mAddon && state.StoreId ? mAddon.map((m, i) => (
                      <tr key={i}>
                        <td>
                          <Checkbox ids="Style" validate={false} value={kitMaterial.some(
                            d =>
                              d.ProductId ===
                              m.productId
                          )} handleChange={e =>
                            handleMaterialSelect(
                              e,
                              m.productId
                            )
                          } disabled={message === 'This Kit is already created , please use the edit option to modify.' ? true : false} />
                        </td>
                        <td>{m.productStructure}</td>
                        <td>{m.privateName}</td>
                        <td>{m.manufacturer}</td>
                        <td>{m.style}</td>
                        <td>{m.color}</td>
                      </tr>
                    )) : (
                      <tr>
                        <td colSpan={`6`}>No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Row>

            <Row className="justify-content-center">
              <h6>Labor Add Ons</h6>
              <div className="gridtable">
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th>Check to Add</th>
                      <th>
                        <Select label={`Product Structure`} handleChange={handleLaborFilterChange} result={otherProductStructure} ids="ProductStructureIdForLabor" validate={false} />
                      </th>
                      <th>
                        <Select label={`Labor Category`} result={laborCategories} ids="LaborCategory" validate={false} handleChange={handleLaborFilterChange} />
                      </th>
                      <th>
                        <Input label={`Labor Name`} ids="LaborName" validate={false} handleChange={handleLaborFilterChange} />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {lAddon && state.StoreId ? lAddon.map((l, i) => (
                      <tr key={i}>
                        <td>
                          <Checkbox ids="Style" validate={false} value={kitLabor.some(
                            d =>
                              d.LaborItemId ===
                              l.laborItemId
                          )} handleChange={e =>
                            handleLaborSelect(
                              e,
                              l.laborItemId
                            )
                          } disabled={message === 'This Kit is already created , please use the edit option to modify.' ? true : false} />
                        </td>
                        <td>{l.productStructure}</td>
                        <td>{l.laborCategory}</td>
                        <td>{l.laborName}</td>
                      </tr>
                    )) : (
                      <tr>
                        <td colSpan={`6`}>No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Row>

          </ModelBody>
          <ModelFooter className="justify-content-between">
            <Button handleClick={hideModel} variant="secondary" type="button">
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isLoading || message === 'This Kit is already created , please use the edit option to modify.' ? true : false}>
              {isLoading ? (<div className="spinner-border"></div>) : "Save"}
            </Button>
          </ModelFooter>
        </Form>
      </Model>
    </Fragment>
  )
}

export default AddEdit;