import React, { Fragment } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';

const CreateStore = ({
	showCreateModal,
	createModalOpen,
	hideCreateModal,
	state,
	handleChange,
	handleCreateModalSubmit,
	StateJson,
	handleStateChange,
	stateVal,
	validated
}) => {

	return (
		<Fragment>
			<Button variant="primary" onClick={showCreateModal}>
				Create New
			</Button>
			<Modal show={createModalOpen} size="md">
				<Form onSubmit={handleCreateModalSubmit} validated={validated} noValidate>
					<Modal.Header>
						<Modal.Title>Add New Store</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row>
							<Col md={12}>
								<Form.Group className="mb-3" controlId="storeName">
									<Form.Label>Store Name<span className="text-danger">*</span></Form.Label>
									<Form.Control type="text" name="storeName" value={state.storeName} onChange={handleChange} required={true} />
									<Form.Control.Feedback type="invalid">
										Store Name is required
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md={12} className="mb-3">
								<Form.Group controlId="address1">
									<Form.Label>Address</Form.Label>
									<Form.Control className="mb-1" type="text" name="address1" value={state.address1} onChange={handleChange} />

								</Form.Group>
								<Form.Group controlId="address2">
									<Form.Control type="text" name="address2" value={state.address2} onChange={handleChange} />
								</Form.Group>
							</Col>
							<Col md={4} className="mb-3">
								<Form.Group controlId="city">
									<Form.Label>City</Form.Label>
									<Form.Control type="text" name="city" value={state.city} onChange={handleChange} />
								</Form.Group>
							</Col>
							<Col md={4} className="mb-3">
								<Form.Group controlId="state">
									<Form.Label>State</Form.Label>
									<CreatableSelect isClearable={stateVal ? true : false} options={StateJson} value={stateVal} onChange={handleStateChange} />

								</Form.Group>
							</Col>
							<Col md={4} className="mb-3">
								<Form.Group controlId="zipcode">
									<Form.Label>Zip Code</Form.Label>
									<Form.Control type="number" name="zipcode" value={state.zipcode} onChange={handleChange} />
								</Form.Group>
							</Col>
							<Col md={6} className="mb-3">
								<Form.Group controlId="phone1">
									<Form.Label>Phone 1</Form.Label>
									<Form.Control type="number" name="phone1" value={state.phone1} onChange={handleChange} />
								</Form.Group>
							</Col><Col md={6} className="mb-3">
								<Form.Group controlId="phone2">
									<Form.Label>Phone 2</Form.Label>
									<Form.Control type="number" name="phone2" value={state.phone2} onChange={handleChange} />
								</Form.Group>
							</Col>
							<Col md={6} className="mb-3">
								<Form.Group controlId="faxno">
									<Form.Label>Fax</Form.Label>
									<Form.Control type="number" name="faxno" value={state.faxno} onChange={handleChange} />
								</Form.Group>
							</Col>
						</Row>

					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={hideCreateModal}>
							Close
						</Button>
						<Button variant="primary" type="submit">
							Save
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</Fragment>
	)
};

export default CreateStore;