import React, { memo } from "react";
import { Modal } from 'react-bootstrap';

const CustomMedel = ({ children, isOpen, size }) => {
  return (

    <Modal show={isOpen} size={size}>
      {children}
    </Modal>

  )
}


export default memo(CustomMedel);