import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Product from "./_routes/productRoute";
import IndexRoute from "./_routes/indexRoute";
import CustomerRoute from "./_routes/customerRoute";
import AdminRoute from "./_routes/adminRoute";
// import NotFound from "./components/pages/notFound/NotFound";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Product />
        <IndexRoute />
        <CustomerRoute />
        <AdminRoute />
        {/* <Routes>
          <Route path="*" element={<NotFound />} />
        </Routes> */}

      </BrowserRouter>
    </div>
  );
};

export default App;
