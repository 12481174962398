import React from "react";
import '../../assets/css/auth.css';
import {Form, Button } from 'react-bootstrap';

function Forgot() {
  return (
    <div className="forgot-wrapper">
      
      <Form className="text-center">
        <h2 className="sarina mb-5">Geoff ERP</h2>
  			<h4 className="title">Forgot your password?</h4>
        <p>Enter your email address and we will send you instructions to reset your password.</p>
  	  	<Form.Group className="mb-3" controlId="formBasicEmail">
  	    	<Form.Control type="email" placeholder="Enter email" />
  	  	</Form.Group>
			  
  	  	<Form.Group className="mb-3" controlId="formBasicCheckbox">
  	  		<Button variant="primary w-100" type="submit">Submit</Button>
  			</Form.Group>

      </Form>
      	
    </div>
  );
}

export default Forgot;
