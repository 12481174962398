import React from "react";
import "../../assets/css/Main.css";
// import Icon from "react-icons-kit";
// import { dollar } from "react-icons-kit/fa";
// import { ic_dashboard } from 'react-icons-kit/md/'

import { Navigation } from "react-minimal-side-navigation";
import { useNavigate, useLocation } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="asideLeft">
      <div className="company">
        <h2 className="text-center sarina">Geoff ERP</h2>
      </div>
      <Navigation
        activeItemId={location.pathname}
        onSelect={({ itemId }) => {
          navigate(itemId);
        }}
        items={[
          {
            title: "Dashboard",
            itemId: "/dashboard",
            // use your own custom Icon component as well
            elemBefore: () => "",
          },
          // {
          //   title: "Contacts Us",
          //   itemId: "/contact-us",
          //   // use your own custom Icon component as well
          //   elemBefore: () => <Icon size={24} icon={ic_contacts} />,
          // },
          // {
          //   title: "Proposals",
          //   // itemId: "/category",
          //   elemBefore: () => <Icon size={24} icon={fileText} />,
          //   subNav: [
          //     {
          //       title: "Proposals List",
          //       itemId: "/ProposalsList",
          //     },
          //     {
          //       title: "Labor Catalog",
          //       itemId: "/category/React",
          //     },
          //     // {
          //     //   title: "Product Catalog",
          //     //   itemId: "/ProductCatalog",
          //     // },
          //   ],
          // },
          {
            title: "Customer Info",
            itemId: "/customer-list",
            elemBefore: () => "",
          },
          {
            title: "Product Catalog",
            itemId: "/ProductCatalog",
            elemBefore: () => "",
          },
          {
            title: "Labor Catalog",
            itemId: "/admin/labor/items",
            elemBefore: () => "",
          },
          {
            title: "Kits",
            itemId: "/kits",
            elemBefore: () => "",
          },
          // {
          //   title: "Orders",
          //   itemId: "/contact",
          //   elemBefore: () => <Icon size={24} icon={dollar} />,
          //   subNav: [
          //     {
          //       title: "About",
          //       itemId: "/contact/about",
          //     },
          //   ],
          // },
          // {
          //   title: "Invoices A/R",
          //   itemId: "/home",
          //   // use your own custom Icon component as well
          //   elemBefore: () => <Icon size={24} icon={dollar} />,
          // },
          // {
          //   title: "Orders",
          //   itemId: "/orders",
          //   // use your own custom Icon component as well
          //   elemBefore: () => <Icon size={24} icon={dollar} />,
          // },
          {
            title: "Admin",
            itemId: "/admin",
            // use your own custom Icon component as well
            elemBefore: () => "",
          },
        ]}
      />
    </div>
  );
};
export default Sidebar;
