import React from "react";
import { Modal } from 'react-bootstrap';

const ModelHeader = ({ children }) => {
  return (
    <Modal.Header>
      <Modal.Title>{children}</Modal.Title>
    </Modal.Header>
  )
}


export default ModelHeader;