import React, { Fragment } from "react";
import Model from "../../elements/CustomModel";
import ModelHeader from "../../elements/ModelHeader";
import ModelBody from "../../elements/ModelBody";
import ModelFooter from "../../elements/ModelFooter";
import Form from "../../elements/CustomForm";
import Select from "../../elements/CustomSelect";
import { Col, Row, Button } from 'react-bootstrap';
const AddStore = ({
  storeList,
  size,
  isCopy,
  handleCopyStoreChange,
  copyProductId,
  copyStoreId,
  prStoreId,
  hideCopyModel,
  handleCopySubmit,
  validatedCopy,
  storeLoading
}) => {
  
  return (
    <Fragment>
      <Model size={size} isOpen={isCopy}>
        <ModelHeader>Copy Product to Save</ModelHeader>
        <Form handleSubmit={handleCopySubmit} validated={validatedCopy}>
          <ModelBody>
            <Row className="justify-content-center">
              <Col md={6} className="mb-3">
                <Select label={`Store Name`} value={copyStoreId} result={storeList} ids="StoreId" handleChange={handleCopyStoreChange} validate={true} />
                {/* {parseInt(copyStoreId) === prStoreId ? (
                  <span className="text-danger">Choose different Store</span>
                ) : null} */}
              </Col>

            </Row>
          </ModelBody>
          <ModelFooter>
            <Button variant="secondary" type="button" onClick={hideCopyModel}>
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={storeLoading}>
              {storeLoading ? (<div className="spinner-border"></div>) : "Save"}

            </Button>
          </ModelFooter>
        </Form>
      </Model>
    </Fragment>
  )

}

export default AddStore;