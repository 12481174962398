import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  PRODUCT_CATALOG_REQUEST,
  PRODUCT_CATALOG_SUCCESS,
  PRODUCT_CATALOG_FAILED,
  PRODUCT_CATALOG_CREATE,
  PRODUCT_CATALOG_CREATE_SUCCESS,
  PRODUCT_CATALOG_CREATE_FAILED,
  PRODUCT_CATALOG_DELETE,
  PRODUCT_CATALOG_DELETE_SUCCESS,
  PRODUCT_CATALOG_DELETE_FAILED,
  PRODUCT_CATALOG_EDIT,
  PRODUCT_CATALOG_EDIT_SUCCESS,
  PRODUCT_CATALOG_EDIT_FAILED,
  PRODUCT_CATALOG_UPDATE,
  PRODUCT_CATALOG_UPDATE_SUCCESS,
  PRODUCT_CATALOG_UPDATE_FAILED
} from "../../../_utils/constants/ProductCatalog";


/*****
 * ProductCatalog Actions
 */

function* fetchProductCatalog({ token, sort, page, paginate, filterState }) {
  try {
    const response = yield call(axios.get, `${API_URL}/product/api/product/GetProductByFilter?PageIndex=${page}&PageSize=${paginate}&sort=${sort}&sortOn=${filterState.sortOn}&ProductId=${filterState.ProductId}&ProductStructureId=${filterState.ProductStructureId}&StoreId=${filterState.StoreId}&PrivateName=${filterState.PrivateName}&Manufacturer=${filterState.Manufacturer}&Style=${filterState.Style}&Color=${filterState.Color}&CurrentCost=${filterState.CurrentCost}&CurrentFreight=${filterState.CurrentFreight}&futureCost1=${filterState.futureCost1}&futureFreight1=${filterState.futureFreight1}&futureCost2=${filterState.futureCost2}&futureFreight2=${filterState.futureFreight2}&IncreaseDate1=${filterState.IncreaseDate1}&IncreaseDate2=${filterState.IncreaseDate2}${filterState.IsStock ? '&IsStock=' + filterState.IsStock : " "}${filterState.IsRecurringStock ? '&IsRecurringStock=' + filterState.IsRecurringStock : " "}`, { headers: { Authorization: `Bearer ${token}` } });

    yield put({ type: PRODUCT_CATALOG_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: PRODUCT_CATALOG_FAILED, payload: error.response });
    }
  }
}

function* createProductCatalog(action) {

  try {
    const response = yield call(
      axios.post,
      `${API_URL}/product/api/product/CreateProduct`,
      action.data, {
      headers: {
        Authorization: `Bearer ${action.token}`
      },
    }
    );
    yield put({
      type: PRODUCT_CATALOG_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_CATALOG_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editProductCatalog(action) {
  try {
    const response = yield call(
      axios.put,
      `${API_URL}/product/api/product/UpdateProduct`,
      action.data, {
      headers: {
        Authorization: `Bearer ${action.token}`
      },
    }
    );
    yield put({
      type: PRODUCT_CATALOG_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_CATALOG_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* updateProductCatalog(action) {
  try {
    const response = yield call(
      axios.put,
      `${API_URL}/product/api/product/UpdateProductDetail`,
      action.data, {
      headers: {
        Authorization: `Bearer ${action.token}`
      },
    }
    );
    yield put({
      type: PRODUCT_CATALOG_UPDATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_CATALOG_UPDATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteProductCatalog(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/product/api/product/RemoveProduct?ProductId=${encodeURIComponent(action.data.ProductId)}`, {
      headers: {
        Authorization: `Bearer ${action.data.token}`
      }
    },
    );
    yield put({
      type: PRODUCT_CATALOG_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_CATALOG_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}



export default function* rootProductCatalogList() {
  yield all([
    takeLatest(PRODUCT_CATALOG_REQUEST, fetchProductCatalog),
    takeLatest(PRODUCT_CATALOG_CREATE, createProductCatalog),
    takeLatest(PRODUCT_CATALOG_DELETE, deleteProductCatalog),
    takeLatest(PRODUCT_CATALOG_EDIT, editProductCatalog),
    takeLatest(PRODUCT_CATALOG_UPDATE, updateProductCatalog),
  ]);
}